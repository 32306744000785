import React, { useEffect, useState } from 'react';
import './App.css';
import './animations.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Cookies from 'js-cookie'

import Home from './pages/Home';
import Features from './pages/Features';
import Contact from './pages/Contact';
import AboutUs from './pages/AboutUs';
import FAQ from './pages/Faq';
import HowItWorks from './pages/HowItWorks';
import Pricing from './pages/Pricing';
import Testimonials from './pages/Testimonials';
import Login2 from './pages/Login';
import Register from './pages/Register';
import Dashboard2 from './pages/Dashboard';
import Payment from './pages/Payment';
import TokenShop from './pages/TokenShop';
import CheckoutSuccess from './pages/Payments/CheckoutSuccess';
import CheckoutCancel from './pages/Payments/CheckoutCancel';

import AdminPage from './pages/AdminPage';
import AmbassadorPage from './pages/AmbassadorPage';
import PromotorsRegistration from './pages/PromotorsRegistration';

import PrivacyPolicy from './pages/Legal/PrivacyPolicy';
import TermsOfService from './pages/Legal/TermsOfService';
import PaymentPolicy from './pages/Legal/PaymentPolicy';
import CookiePolicy from './pages/Legal/CookiePolicy';
import ReferralProgramRegulation from './pages/Legal/ReferralProgramRegulation';

import PasswordResetRequest from './pages/ResetPassword/PasswordResetRequest';
import ResetPassword from './pages/ResetPassword/ResetPassword';

import VerifyEmailNotification from './pages/VerifyEmailNotification';
import VerifyEmail from './pages/VerifyEmail';
import Layout from './components/Layout';
import ActionHandler from './pages/ActionHandler';

import { ProtectedRoute } from './components/ProtectedRoute'
import ScrollToTop from "./components/scrollToTop";
import { useLocation } from 'react-router-dom';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import { initGA, logPageView } from './analytics';
import ManageSubscription from './components/dashboard/Payments/ManageSubscription';
import Manage from './pages/Manage';



function App() {
  return (
    <Router>
      <ScrollToTop />
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();

  const [country, setCountry] = useState("UNKNOWN");


  useEffect(() => {
    const consent = getCookieConsentValue();
    if (consent === 'true') {
      initGA();
      logPageView(location.pathname + location.search);
    }
  }, []);

  useEffect(() => {
    const consent = getCookieConsentValue();
    if (consent === 'true') {
      logPageView(location.pathname + location.search);
    }
  }, [location]);


  useEffect(() => {
    initGA();
  }, []);

  useEffect(() => {
    logPageView(location.pathname + location.search);
  }, [location]);


  async function getCountryFromBackend() {
    try {
      const res = await fetch("https://us-central1-quizonator-a4513.cloudfunctions.net/api/getCountry");

      if (!res.ok) throw new Error("Błąd pobierania kraju");
      const data = await res.json();
      return data.country;
    } catch (error) {
      console.error(error);
      return "UNKNOWN";
    }
  }
  useEffect(() => {
    getCountryFromBackend().then((countryCode) => {
      setCountry(countryCode);
    });
  }, []);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const refCode = searchParams.get('ref');
    if (refCode && !Cookies.get('refTracked')) {
      Cookies.set('refCode', refCode, { expires: 30 });
      Cookies.set('refTracked', 'true', { expires: 1 });
      fetch('https://us-central1-quizonator-a4513.cloudfunctions.net/api/trackReferralClick', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ referralCode: refCode })
      }).catch(error =>
        console.error("Błąd podczas trackowania referral kliknięcia:", error)
      );
    }
  }, []);


  return (
    <>
      <ScrollToTop />
      <Layout country={country}>
        <Routes>
          <Route path="/__/auth/action" element={<ActionHandler />} />
          <Route path="/" element={<Home />} />
          <Route path="/zalety" element={<Features />} />
          <Route path="/cennik" element={<Pricing country={country} />} />
          <Route path="/o-nas" element={<AboutUs />} />
          <Route path="/jak-to-dziala" element={<HowItWorks />} />
          <Route path="/opinie" element={<Testimonials />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/login" element={<Login2 />} />
          <Route path="/register" element={<Register />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/checkout-success" element={<CheckoutSuccess />} />
          <Route path="/checkout-cancel" element={<CheckoutCancel />} />
          {/* <Route path="/manage-subscription" element={<Manage />} /> */}
          <Route path="/tokens" element={<TokenShop country={country} />} />
          <Route path="/zarabiaj" element={<PromotorsRegistration />} />
          <Route path="/panel-promotora" element={<ProtectedRoute><AmbassadorPage /></ProtectedRoute>} />

          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard2 />
              </ProtectedRoute>
            }
          />
          <Route path="/polityka-prywatnosci" element={<PrivacyPolicy />} />
          <Route path="/warunki-uzytkowania" element={<TermsOfService />} />
          <Route path="/polityka-platnosci" element={<PaymentPolicy />} />
          <Route path="/program-referencyjny" element={<ReferralProgramRegulation />} />
          <Route path="/polityka-cookies" element={<CookiePolicy />} />

          <Route path="/forgot-password" element={<PasswordResetRequest />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          <Route path="/verify-email-notification" element={<VerifyEmailNotification />} />
          <Route path="/verify-email" element={<VerifyEmail />} />

          {/* <Route path="/ambassador" element={<AmbassadorPage />} />
          <Route path="/admin" element={<AdminPage />} /> */}

          <Route path="*" element={<Home />} />

        </Routes>
      </Layout>
      <CookieConsent
        location="bottom"
        buttonText="Akceptuję"
        declineButtonText="Odrzuć"
        enableDeclineButton
        onAccept={() => {
          initGA();
          logPageView(window.location.pathname + window.location.search);
        }}
        onDecline={() => {
          // Opcjonalnie: akcje po odrzuceniu
        }}
        style={{
          background: '#1B4965',
          color: '#FFFFFF',
          fontSize: '14px',
          alignItems: 'center',
        }}
        buttonStyle={{
          background: '#00D1A7',
          color: '#FFFFFF',
          fontSize: '14px',
          borderRadius: '4px',
        }}
        declineButtonStyle={{
          background: '#FFFFFF',
          color: '#1B4965',
          fontSize: '14px',
          borderRadius: '4px',
          marginLeft: '10px',
        }}
        buttonWrapperClasses="flex justify-center mt-4"
      >
        <div className="text-center">
          Ta strona używa plików cookies w celach analitycznych i marketingowych.{' '}
          <Link to="/polityka-cookies" className="underline text-[#00D1A7]">
            Dowiedz się więcej
          </Link>
        </div>
      </CookieConsent>
    </>
  );
}

export default App;
