import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../components/ui/card";
import { Star } from 'lucide-react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function Testimonials() {
    const { t } = useTranslation();

    const testimonials = [
        { name: "Bartek", school: "Akademia Humanitas", quote: t("testimonials.quote1"), rating: 5 },
        { name: "Dominika", school: "Uniwersytet WSB Merito", quote: t("testimonials.quote2"), rating: 5 },
        { name: "Wiktor", school: "Uniwersytet WSB Merito", quote: t("testimonials.quote3"), rating: 5 },
        { name: "Marta", school: "Uniwersytet Warszawski", quote: t("testimonials.quote4"), rating: 4 },
        { name: "Krzysiek", school: "Politechnika Łódzka", quote: t("testimonials.quote5"), rating: 5 },
        { name: "Ania", school: "Uniwersytet Ekonomiczny w Katowicach", quote: t("testimonials.quote6"), rating: 4 },
        { name: "Seba", school: "Uniwersytet Przyrodniczy we Wrocławiu", quote: t("testimonials.quote7"), rating: 5 },
        { name: "Zosia", school: "Uniwersytet im. Adama Mickiewicza w Poznaniu", quote: t("testimonials.quote8"), rating: 5 },
        { name: "Tomek", school: "Politechnika Krakowska", quote: t("testimonials.quote9"), rating: 4 },
        { name: "Natalia", school: "Uniwersytet Medyczny w Lublinie", quote: t("testimonials.quote10"), rating: 5 },
    ];

    return (
        <>
            <Helmet>
                <title>{t("testimonials.meta.title")}</title>
                <meta name="description" content={t("testimonials.meta.description")} />
            </Helmet>

            <section className="py-20 md:py-32 bg-gradient-to-b from-gray-50 to-white min-h-screen">
                <div className="container max-w-7xl px-6 md:px-12 mx-auto">
                    <div className="text-center mb-16">
                        <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold bg-gradient-to-r from-[#1B4965] to-[#3B82F6] bg-clip-text text-transparent mb-4">
                            {t("testimonials.title")}
                        </h1>
                        <p className="text-lg md:text-xl text-gray-600 max-w-2xl mx-auto leading-relaxed">
                            {t("testimonials.subtitle")}
                        </p>
                        <div className="mt-4 h-1 w-20 bg-gradient-to-r from-[#1B4965] to-[#3B82F6] mx-auto rounded-full" />
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                        {testimonials.map((testimonial, index) => (
                            <Card
                                key={index}
                                className="bg-white shadow-md hover:shadow-2xl transition-all duration-300 rounded-2xl p-6 text-center transform hover:-translate-y-2 border-t-4 border-[#1B4965]"
                            >
                                <CardHeader className="relative">
                                     
                                    <CardTitle className="text-xl font-semibold text-[#1B4965] mt-4">
                                        {testimonial.name}
                                    </CardTitle>
                                    <CardDescription className="text-sm text-gray-500 font-medium">
                                        {testimonial.school}
                                    </CardDescription>
                                </CardHeader>
                                <CardContent>
                                    <p className="text-gray-700 mb-4 italic text-base leading-snug">
                                        "{testimonial.quote}"
                                    </p>
                                    <div className="flex justify-center gap-1">
                                        {Array.from({ length: 5 }).map((_, i) => (
                                            <Star
                                                key={i}
                                                className={`h-5 w-5 transition-colors duration-200 ${i < testimonial.rating ? 'text-yellow-400' : 'text-gray-300'
                                                    }`}
                                                fill={i < testimonial.rating ? 'currentColor' : 'none'}
                                            />
                                        ))}
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}