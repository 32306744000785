import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { auth } from "../firebase";
import { useNavigate } from 'react-router-dom';

function AdminPage() {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (!user) {
                navigate('/login');
                return;
            }
            try {
                const idToken = await user.getIdToken();
                // Wywołaj endpoint admina:
                const res = await axios.get(
                    'https://us-central1-quizonator-a4513.cloudfunctions.net/api/admin/users',
                    {
                        headers: { Authorization: `Bearer ${idToken}` }
                    }
                );
                setUsers(res.data.users); // załóżmy, że zwraca { users: [...] }
            } catch (err) {
                console.error(err);
                setError('Brak uprawnień lub błąd pobierania danych.');
            } finally {
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    if (loading) {
        return <div>Ładowanie...</div>;
    }
    if (error) {
        return <div className="text-red-600">{error}</div>;
    }

    const handleMakeAmbassador = async (email) => {
        try {
            const user = auth.currentUser;
            if (!user) return;
            const idToken = await user.getIdToken();
            await axios.post(
                'https://us-central1-quizonator-a4513.cloudfunctions.net/api/admin/makeAmbassador',
                { email },
                { headers: { Authorization: `Bearer ${idToken}` } }
            );
            alert(`Użytkownik ${email} został ambasadorem`);
        } catch (err) {
            console.error(err);
            alert('Błąd przy nadawaniu roli ambasadora.');
        }
    };

    return (
        <div className="p-4 max-w-4xl mx-auto">
            <h1 className="text-2xl font-bold mb-4">Panel Administratora</h1>
            <table className="w-full border">
                <thead>
                    <tr>
                        <th className="border p-2">Email</th>
                        <th className="border p-2">Rola</th>
                        <th className="border p-2">Akcje</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((u) => (
                        <tr key={u.email}>
                            <td className="border p-2">{u.email}</td>
                            <td className="border p-2">{u.role}</td>
                            <td className="border p-2">
                                {u.role !== 'ambassador' && (
                                    <button
                                        onClick={() => handleMakeAmbassador(u.email)}
                                        className="px-4 py-2 bg-blue-500 text-white rounded"
                                    >
                                        Nadaj ambasadora
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default AdminPage;
