// src/pages/Dashboard.jsx
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { auth } from "../firebase";
import { useNavigate } from 'react-router-dom';
import {
    CreditCard,
    BarChart2,
    GraduationCap,
    User,
} from 'lucide-react';
import { Button } from '../components/ui/button';
import ChangePasswordModal from '../components/dashboard/modals/ChangePasswordModal';
import DeleteAccountModal from '../components/dashboard/modals/DeleteAccountModal';
import EmailVerificationModal from '../components/dashboard/modals/EmailVerificationModal';
import DashboardTile from '../components/dashboard/DashboardTile';
import Payments from '../components/dashboard/Payments/Payments';
import Account from '../components/dashboard/Account';
import Statistics from '../components/dashboard/Statistics';
import Quizzes from '../components/dashboard/Quizzes';
import QuizDetails from '../components/dashboard/QuizDetails';
import { EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { sendEmailVerification } from 'firebase/auth';
import { Progress } from '../components/ui/progress';
import TutorialOverlay from '../components/dashboard/TutorialOverlay';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../components/ui/card"
import { logEvent } from '../analytics';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { TokenPurchaseModal } from '../components/dashboard/modals/TokenPurchaseModal';

const Dashboard = () => {
    const { t } = useTranslation();
    const [userData, setUserData] = useState(null);
    const [quizSummaries, setQuizSummaries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeSection, setActiveSection] = useState('dashboard');
    const [selectedQuiz, setSelectedQuiz] = useState(null);
    const [showVerifyPopup, setShowVerifyPopup] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [user, setUser] = useState(null);
    const [transactions, setTransactions] = useState([]);

    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
    const [showTokenPurchaseModal, setShowTokenPurchaseModal] = useState(false);


    const [isTutorialCompleted, setIsTutorialCompleted] = useState(false); // Stan dla tutoriala
    const [tutorialStep, setTutorialStep] = useState(() => {
        const savedTutorialStep = localStorage.getItem('tutorialStep');
        return savedTutorialStep !== null ? parseInt(savedTutorialStep, 10) : 0;
    });

    const [isTutorialOpen, setIsTutorialOpen] = useState(() => {
        const savedIsTutorialOpen = localStorage.getItem('isTutorialOpen');
        return savedIsTutorialOpen !== null ? savedIsTutorialOpen === 'true' : false;
    });
    function isSubscriptionActive(userData) {
        console.debug("Sprawdzanie aktywności subskrypcji dla użytkownika:", userData);
        if (!userData?.subscriptionEndDate) {
            console.debug("Brakująca data końca subskrypcji, subskrypcja nieaktywna");
            return false;
        }
        const now = new Date();
        console.debug("Aktualna data:", now);
        let endDate;
        if (typeof userData.subscriptionEndDate === 'string') {
            endDate = new Date(userData.subscriptionEndDate);
        } else if (typeof userData.subscriptionEndDate === 'object' && userData.subscriptionEndDate._seconds) {
            endDate = new Date(userData.subscriptionEndDate._seconds * 1000 + userData.subscriptionEndDate._nanoseconds / 1000000);
        } else {
            console.error("Nieobsługiwany format daty końca subskrypcji");
            return false;
        }
        console.debug("Data końca subskrypcji:", endDate);
        return endDate > now;
    }

    const handleTutorialClose = () => {
        setIsTutorialOpen(false);
        localStorage.setItem('tutorialSeen', 'true');

        // Jeśli email nie jest zweryfikowany, pokaż popup po zamknięciu tutoriala
        if (!user?.emailVerified) {
            setShowVerifyPopup(true);
        }
    };

    const navigate = useNavigate();
    const dashboardRefs = {
        payments: useRef(null),
        quizzes: useRef(null),
        // statistics: useRef(null),
        account: useRef(null),
        // Dodaj kolejne referencje, jeśli potrzebujesz
    };


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
            setUser(currentUser);

            if (currentUser) {
                // Pobierz z localStorage informację, czy tutorial był już widziany
                const tutorialSeen = localStorage.getItem('tutorialSeen');

                if (!tutorialSeen) {
                    // Jeśli tutorial nie był widziany, pokaż tutorial
                    setIsTutorialOpen(true);
                }

                // Ustawienie zmiennej isVerified na podstawie stanu emailVerified
                if (currentUser.emailVerified) {
                    setIsVerified(true);
                    setShowVerifyPopup(false); // Ukryj popup, jeśli email jest zweryfikowany
                } else {
                    setIsVerified(false);
                    setShowVerifyPopup(true); // Pokaż popup, jeśli email nie jest zweryfikowany
                }


                // Pobierz dane użytkownika
                fetchUserData(currentUser);
            } else {
                setLoading(false);
            }
        });

        // Sprawdź, czy tutorial został już ukończony
        const tutorialCompleted = localStorage.getItem('tutorialCompleted');
        setIsTutorialCompleted(tutorialCompleted === 'true');

        // Odczytaj tutorialStep i isTutorialOpen z localStorage
        const savedTutorialStep = localStorage.getItem('tutorialStep');
        if (savedTutorialStep !== null) {
            setTutorialStep(parseInt(savedTutorialStep, 10));
        }
        const savedIsTutorialOpen = localStorage.getItem('isTutorialOpen');
        if (savedIsTutorialOpen !== null) {
            setIsTutorialOpen(savedIsTutorialOpen === 'true');
        }

        return () => unsubscribe();
    }, [user]);

    useEffect(() => {
        localStorage.setItem('tutorialStep', tutorialStep);
    }, [tutorialStep]);

    useEffect(() => {
        localStorage.setItem('isTutorialOpen', isTutorialOpen);
    }, [isTutorialOpen]);


    const handleTutorialComplete = () => {
        setIsTutorialCompleted(true);
        setIsTutorialOpen(false);
        setTutorialStep(0)
        localStorage.setItem('tutorialCompleted', 'true');
        localStorage.setItem('tutorialSeen', 'true');
    };


    const fetchUserData = async (currentUser) => {
        try {
            // Pobierz token ID użytkownika
            const idToken = await currentUser.getIdToken();

            // Wyślij żądanie do backendu z tokenem w nagłówkach
            const response = await axios.post(
                'https://us-central1-quizonator-a4513.cloudfunctions.net/api/getUserDataWithQuizSummaries',
                {}, // Puste ciało żądania
                {
                    headers: {
                        Authorization: `Bearer ${idToken}`, // Przekaż token uwierzytelniający
                    },
                }
            );

            // Przetwarzanie odpowiedzi z backendu
            const userDataResponse = response.data.userData;
            const quizSummariesResponse = response.data.quizSummaries;
            const transactionsResponse = response.data.transactions; // Pobierz transakcje

            // Przetwórz dane użytkownika
            const processedUserData = {
                userName: userDataResponse.userName || "Nieznany użytkownik",
                subscriptionStatus: userDataResponse.subscriptionStatus || "Brak subskrypcji",
                subscriptionEndDate: userDataResponse.subscriptionEndDate || "N/A",
                requestCount: userDataResponse.requestCount || 0,
                requestLimit: userDataResponse.requestLimit || 0,
                tokenBalance: userDataResponse.tokenBalance || 0,
                email: userDataResponse.email,
                role: userDataResponse.role
            };

            // Przetwórz podsumowania quizów
            const processedQuizSummaries = quizSummariesResponse.map((quiz) => ({
                id: quiz.id,
                courseName: quiz.courseName || "Brak nazwy kursu",
                data: {
                    Ocena: quiz.data.Ocena || "Brak oceny",
                    Rozpoczęto: quiz.data.Rozpoczęto || "Brak danych",
                    Ukończono: quiz.data.Ukończono || "Brak danych",
                    Stan: quiz.data.Stan || "Brak danych",
                    "Wykorzystany czas": quiz.data["Wykorzystany czas"] || "Brak danych",
                    testName: quiz.testName || "Brak nazwy testu",
                    timestamp: quiz.timestamp || "Brak danych",
                    universityUrl: quiz.universityUrl || "",
                    userEmail: quiz.data.userEmail || "",
                    questions: quiz.questions || [],
                },
            }));

            // Przetwórz transakcje
            const processedTransactions = transactionsResponse.map((transaction) => ({
                id: transaction.id,
                date: transaction.date && transaction.date._seconds
                    ? new Date(transaction.date._seconds * 1000).toLocaleDateString("pl-PL", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                    })
                    : "Brak daty",
                description: transaction.description || "Brak opisu",
                amount: transaction.amount || "Brak kwoty",
                status: transaction.status || "Nieznany status",
            }));

            // Ustaw dane w stanie
            setUserData(processedUserData);
            setQuizSummaries(processedQuizSummaries);
            setTransactions(processedTransactions); // Dodaj transakcje do stanu
        } catch (error) {
            console.error("Błąd podczas pobierania danych użytkownika:", error);
        } finally {
            setLoading(false);
        }
    };
    const handleResendVerification = async () => {
        try {
            const user = auth.currentUser;
            if (user && !user.emailVerified) {
                const idToken = await user.getIdToken();

                const response = await axios.post(
                    'https://us-central1-quizonator-a4513.cloudfunctions.net/api/send-verification-email',
                    { email: user.email },
                    {
                        headers: {
                            'Authorization': `Bearer ${idToken}`,
                        },
                    }
                );

                if (response.status === 200) {
                    // Sukces, nie musisz nic więcej robić tutaj, komunikat zostanie wyświetlony w modalu
                } else {
                    throw new Error('Błąd podczas wysyłania emaila weryfikacyjnego.');
                }
            } else if (user && user.emailVerified) {
                throw new Error('Twój adres email jest już zweryfikowany.');
            } else {
                throw new Error('Nie jesteś zalogowany.');
            }
        } catch (error) {
            console.error('Błąd podczas wysyłania emaila weryfikacyjnego:', error);
            throw error; // Przekaż błąd do modalu
        }
    };


    const handleChangePassword = async (currentPassword, newPassword) => {
        try {
            const user = auth.currentUser;

            if (!user) {
                throw new Error("Nie znaleziono zalogowanego użytkownika.");
            }

            // Pobierz token ID użytkownika
            const idToken = await user.getIdToken();


            // Wyślij żądanie do backendu
            const response = await axios.post(
                'https://us-central1-quizonator-a4513.cloudfunctions.net/api/change-password',
                { newPassword },
                {
                    headers: {
                        Authorization: `Bearer ${idToken}` // Przekaż token ID w nagłówkach
                    }
                }
            );

            alert(response.data.message); // Komunikat sukcesu z backendu
        } catch (error) {
            console.error("Błąd podczas zmiany hasła:", error);
            alert(error.response?.data?.error || "Nie udało się zmienić hasła.");
        }
    };




    const handleDeleteAccount = async () => {
        try {
            const password = prompt('Aby usunąć konto, proszę wprowadź swoje hasło:');
            if (!password) {
                throw new Error('Hasło jest wymagane do usunięcia konta.');
            }

            const user = auth.currentUser;
            if (user) {
                const credential = EmailAuthProvider.credential(user.email, password);
                await reauthenticateWithCredential(user, credential);
                await user.delete();
                navigate('/register');
            } else {
                throw new Error('Nie udało się znaleźć zalogowanego użytkownika.');
            }
        } catch (error) {
            console.error('Błąd podczas usuwania konta:', error);
            throw error;
        }
    };

    // Przykład w React (pseudo-kod):
    const handlePurchaseTokens = async (pkg) => {
        try {
            const token = await user.getIdToken();

            const response = await fetch("https://us-central1-quizonator-a4513.cloudfunctions.net/api/create-checkout-session", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    email: user.email,
                    userId: user.uid,
                    planType: "tokens",
                    tokens: pkg.tokens,    // np. 200
                    price: pkg.price,      // np. 12.49
                }),
            });

            const data = await response.json();
            if (data.url) {
                window.location.href = data.url; // do Stripe Checkout
            }
        } catch (err) {
            console.error("Error creating checkout session:", err);
        }
    };

    const renderSkeletonLoader = () => (
        <div className="min-h-screen bg-background p-8 bg-gray-50">
            <div className="max-w-6xl mx-auto space-y-6">

                {/* Sekcja powitalna */}
                <div className="bg-white p-4 sm:p-6 rounded-lg border border-gray-300">
                    <div className="h-6 w-48 bg-gray-200 rounded animate-pulse mb-2"></div>
                    <div className="h-4 w-64 bg-gray-200 rounded animate-pulse"></div>
                </div>

                {/* Karty statystyk (3 sztuki) */}
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
                    {Array.from({ length: 3 }).map((_, i) => (
                        <div key={i} className="border border-gray-300 p-4 sm:p-6 rounded-lg bg-white space-y-4">
                            <div className="flex items-center justify-between">
                                <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div>
                                <div className="h-5 w-5 bg-gray-200 rounded-full animate-pulse"></div>
                            </div>
                            <div className="space-y-2">
                                <div className="h-6 w-24 bg-gray-200 rounded animate-pulse"></div>
                                <div className="h-2 w-full bg-gray-200 rounded animate-pulse"></div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Kafelki nawigacyjne (3 sztuki) */}
                <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-4">
                    {Array.from({ length: 3 }).map((_, i) => (
                        <div key={i} className="p-4 sm:p-6 hover:shadow-lg transition-shadow rounded-lg border border-gray-300 bg-white">
                            <div className="h-5 w-24 bg-gray-200 rounded animate-pulse mb-2"></div>
                            <div className="h-4 w-32 bg-gray-200 rounded animate-pulse mb-4"></div>
                            <div className="h-5 w-5 bg-gray-200 rounded-full animate-pulse"></div>
                        </div>
                    ))}
                </div>

                {/* Sekcja pomocy */}
                <div className="border border-gray-300 rounded-lg bg-white p-4 sm:p-6">
                    <div className="h-5 w-32 bg-gray-200 rounded animate-pulse mb-2"></div>
                    <div className="h-4 w-64 bg-gray-200 rounded animate-pulse mb-4"></div>
                    <div className="h-10 w-32 bg-gray-200 rounded animate-pulse"></div>
                </div>
            </div>
        </div>
    );
    function parseDate(date) {
        // Jeśli data jest obiektem Timestamp (Firestore)
        if (typeof date === "object" && date._seconds) {
            return new Date(date._seconds * 1000); // Konwersja z sekund na milisekundy
        }
        // Jeśli data jest stringiem
        if (typeof date === "string") {
            return new Date(date); // Bezpośrednia konwersja z ciągu znaków
        }
        // Jeśli format jest nieobsługiwany, zwróć invalid date
        return new Date(NaN);
    }

    const renderDashboard = () => (
        <>
            <Helmet>
                <title>{t("dashboard.meta.title")}</title>
                <meta
                    name="description"
                    content={t("dashboard.meta.description")}
                />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>


            <div className="space-y-6">
                {/* Welcome Header */}
                <div className="bg-white p-4 sm:p-6 rounded-lg border border-gray-300 text-center sm:text-left">
                    <h1 className="text-2xl sm:text-3xl font-bold text-gray-800">
                        {t("dashboard.welcome.title", { userName: userData?.userName || t("dashboard.welcome.defaultUserName") })}
                    </h1>
                    <p className="text-sm sm:text-base text-gray-600 mt-2">
                        {t("dashboard.welcome.description")}
                    </p>
                </div>


                {/* Statistics Section */}
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
                    <Card className="border border-gray-300 p-4 sm:p-6">
                        <CardHeader className="flex flex-row items-center justify-between">
                            <CardTitle className="text-sm sm:text-base font-medium">{t("dashboard.stats.requestsUsed")}</CardTitle>
                            <CreditCard className="h-5 w-5 sm:h-6 sm:w-6 text-muted-foreground" />
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-2">
                                <div className="flex justify-between text-lg sm:text-2xl font-bold">
                                    <span className={
                                        userData?.requestCount <= userData?.requestLimit
                                            ? "text-black"
                                            : "bg-gradient-to-r from-green-400 to-green-600 bg-clip-text text-transparent"
                                    }>
                                        {userData?.requestCount} / {userData?.requestLimit}
                                    </span>
                                    <span>
                                        +{userData?.tokenBalance || 0}
                                    </span>
                                </div>
                                <div className="relative">
                                    <Progress
                                        value={
                                            userData?.requestCount <= userData?.requestLimit
                                                ? (userData?.requestCount / (userData?.requestLimit || 1)) * 100
                                                : Math.min(
                                                    (userData?.requestCount / (userData?.tokenBalance || 1)) * 100,
                                                    100
                                                )
                                        }
                                        className="bg-gray-200 h-2"
                                        indicatorClassName={
                                            userData?.requestCount <= userData?.requestLimit
                                                ? "bg-blue-500"
                                                : "bg-gradient-to-r from-green-400 to-green-600"
                                        }
                                    />
                                    {userData?.requestCount > userData?.requestLimit && (
                                        <div className="text-xs text-gray-500 mt-1">
                                            Używane bonusowe tokeny
                                        </div>
                                    )}
                                </div>
                            </div>
                        </CardContent>
                    </Card>


                    <Card className="border border-gray-300 p-4 sm:p-6">
                        <CardHeader className="flex flex-row items-center justify-between">
                            <CardTitle className="text-sm sm:text-base font-medium">{t("dashboard.stats.solvedQuizzes")}</CardTitle>
                            <GraduationCap className="h-5 w-5 sm:h-6 sm:w-6 text-muted-foreground" />
                        </CardHeader>
                        <CardContent>
                            <div className="text-lg sm:text-2xl font-bold">{quizSummaries?.length || 0}</div>
                            <p className="text-xs sm:text-sm text-muted-foreground">
                                {quizSummaries?.length
                                    ? `${t("dashboard.stats.lastQuiz")}: ${quizSummaries[quizSummaries.length - 1]?.data.Ukończono}`
                                    : t("dashboard.stats.noQuizzes")}
                            </p>
                        </CardContent>
                    </Card>

                    <Card className='border border-gray-300 p-4 sm:p-6'>
                        <CardHeader className="flex flex-row items-center justify-between space-y-0">
                            <CardTitle className="text-sm sm:text-base font-medium">{t("dashboard.stats.subscriptionStatus")}</CardTitle>
                            <User className="h-5 w-5 sm:h-6 sm:w-6 text-muted-foreground" />
                        </CardHeader>
                        <CardContent>
                            <div className="flex justify-between items-center">
                                <div className="text-lg sm:text-2xl font-bold">
                                    {userData?.subscriptionEndDate
                                        ? new Date() < parseDate(userData.subscriptionEndDate)
                                            ? t("dashboard.stats.active") // Subskrypcja aktywna
                                            : t("dashboard.stats.inactive") // Subskrypcja nieaktywna
                                        : t("dashboard.stats.inactive")}
                                </div>
                                {(userData.subscriptionEndDate === "N/A" ||
                                    new Date() > parseDate(userData.subscriptionEndDate) ||
                                    (userData?.requestLimit - userData?.requestCount) <= 0) && (
                                        <Button
                                            variant="default"
                                            className="mt-4 bg-gray-800 text-white hover:bg-gray-900"
                                            onClick={() => navigate('/cennik')}
                                        >
                                            Aktywuj
                                            {t("dashboard.activate")}
                                        </Button>
                                    )}


                            </div>

                            <p className="text-xs sm:text-sm text-muted-foreground">
                                {t("dashboard.stats.validUntil")}{" "}
                                {userData?.subscriptionEndDate
                                    ? parseDate(userData.subscriptionEndDate).toLocaleDateString()
                                    : "n/a"}
                            </p>

                        </CardContent>


                    </Card>

                </div>

                {/* Navigation Tiles */}
                <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-4">
                    <DashboardTile
                        title={t("dashboard.tiles.payments.title")}
                        description={t("dashboard.tiles.payments.description")}
                        Icon={CreditCard}
                        onClick={() => {
                            setActiveSection('payments');
                            window.scrollTo(0, 0);
                            logEvent('Dashboard', 'Click', t("dashboard.log.payments"));
                        }}
                        ref={dashboardRefs.payments}
                        className="p-4 sm:p-6 hover:shadow-lg transition-shadow rounded-lg"
                    />

                    <DashboardTile
                        title={t("dashboard.tiles.account.title")}
                        description={t("dashboard.tiles.account.description")}
                        Icon={User}
                        onClick={() => {
                            setActiveSection('account');
                            window.scrollTo(0, 0);
                            logEvent('Dashboard', 'Click', t("dashboard.log.account"));
                        }}
                        ref={dashboardRefs.account}
                        className="p-4 sm:p-6 hover:shadow-lg transition-shadow rounded-lg"
                    />
                    {/* <DashboardTile
                    title="Statystyki"
                    description="Sprawdź swoje postępy"
                    Icon={BarChart2}
                    onClick={() => {
                        setActiveSection('statistics');
                        window.scrollTo(0, 0);
                    }}
                    ref={dashboardRefs.statistics}
                    className="p-4 sm:p-6 hover:shadow-lg transition-shadow rounded-lg"
                /> */}
                    {/* {userData?.role === 'ambassador' && (
                        <div className="bg-yellow-100 text-yellow-800 p-4 rounded mb-4">
                            Jesteś ambasadorem! Dziękujemy za wsparcie.
                            <br />
                            <button
                                className="mt-2 py-2 px-4 bg-yellow-400 text-white rounded"
                                onClick={() => navigate('/ambassador')}
                            >
                                Przejdź do panelu ambasadora
                            </button>
                        </div>
                    )}
                    {userData?.role === 'admin' && (
                        <div className="bg-yellow-100 text-yellow-800 p-4 rounded mb-4">
                            Jesteś administratorem! Witaj w panelu admina.
                            <br />
                            <button
                                className="mt-2 py-2 px-4 bg-yellow-400 text-white rounded"
                                onClick={() => navigate('/admin')}
                            >
                                Przejdź do panelu Admina
                            </button>
                        </div>
                    )} */}
                    <DashboardTile
                        title={t("dashboard.tiles.quizzes.title")}
                        description={t("dashboard.tiles.quizzes.description")}
                        Icon={GraduationCap}
                        onClick={() => {
                            setActiveSection('quizzes');
                            window.scrollTo(0, 0);
                            logEvent('Dashboard', 'Click', t("dashboard.log.quizzes"));
                        }}
                        ref={dashboardRefs.quizzes}
                        className="p-4 sm:p-6 hover:shadow-lg transition-shadow rounded-lg"
                    />
                </div>
                {new Date() < parseDate(userData.subscriptionEndDate) && (
                    <div className="mt-8 bg-white border border-gray-300 rounded-lg p-6 text-center">
                        <h2 className="text-xl font-bold text-[#1B4965] mb-2">
                            {t("dashboard.subscription.activeTitle")}
                        </h2>
                        <p className="text-gray-700 mb-4">
                            {t("dashboard.subscription.activeDescription")}
                        </p>
                        <Button
                            className="bg-gradient-to-r from-[#FF7E5F] to-[#FD3A84] text-white hover:opacity-90 font-medium px-6 py-3 rounded-lg text-lg"
                            onClick={() => navigate("/tokens")}
                        >
                            {t("dashboard.subscription.buyTokens")}
                        </Button>
                    </div>
                )}
                <Card className='border border-gray-300'>
                    <CardHeader>
                        <CardTitle className="text-lg sm:text-2xl font-semibold">{t("dashboard.help.title")}</CardTitle>
                        <CardDescription className="text-sm sm:text-base">
                            {t("dashboard.help.description")}
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <Button onClick={() => {
                            setIsTutorialOpen(true);
                        }} className="mt-4 bg-black text-white">
                            {t("dashboard.help.startTutorial")}
                        </Button>
                    </CardContent>
                </Card>

            </div>
        </>
    );

    const renderPayments = () => (
        <Payments userData={userData} transactions={transactions} onBack={() => setActiveSection('dashboard')} />
    );

    const renderAccount = () => (
        <Account
            userData={userData}
            onBack={() => setActiveSection('dashboard')}
            onOpenChangePassword={() => setShowChangePasswordModal(true)}
            onOpenDeleteAccount={() => setShowDeleteAccountModal(true)}
        />
    );

    const renderStatistics = () => (
        <Statistics userData={userData} onBack={() => setActiveSection('dashboard')} />
    );

    const renderQuizzes = () => (
        <Quizzes
            quizSummaries={quizSummaries}
            onBack={() => setActiveSection('dashboard')}
            onSelectQuiz={(quiz) => setSelectedQuiz(quiz)}
        />
    );

    const renderQuizDetails = () => (
        <QuizDetails
            quiz={selectedQuiz}
            onBack={() => setSelectedQuiz(null)}
        />
    );

    if (loading) {
        return (
            <div className="min-h-screen bg-background p-8 bg-gray-50">
                <div className="max-w-6xl mx-auto">
                    {renderSkeletonLoader()}
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-white p-8">
            <div className="max-w-6xl mx-auto">

                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeSection + (selectedQuiz ? 'quizDetails' : '')}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.2 }}
                    >
                        {activeSection === 'dashboard' && renderDashboard()}
                        {activeSection === 'payments' && renderPayments()}
                        {activeSection === 'account' && renderAccount()}
                        {activeSection === 'statistics' && renderStatistics()}
                        {activeSection === 'quizzes' &&
                            (selectedQuiz ? renderQuizDetails() : renderQuizzes())}
                    </motion.div>
                </AnimatePresence>
                {isTutorialOpen && (
                    <TutorialOverlay
                        tutorialStep={tutorialStep}
                        setTutorialStep={setTutorialStep}
                        onComplete={handleTutorialComplete}
                        onClose={handleTutorialClose} // Dodaj tę linię
                        dashboardRefs={dashboardRefs}
                        isVerified={isVerified}
                        sendVerificationEmail={handleResendVerification}
                    />
                )}

            </div>

            {/* Modal do weryfikacji emaila */}
            <AnimatePresence>
                {!isTutorialOpen && showVerifyPopup && (
                    <EmailVerificationModal
                        isOpen={showVerifyPopup}
                        onClose={() => setShowVerifyPopup(false)}
                        onResendVerification={handleResendVerification}
                        userEmail={user.email}
                    />
                )}
            </AnimatePresence>

            {/* Modal do zmiany hasła */}
            <ChangePasswordModal
                isOpen={showChangePasswordModal}
                onClose={() => setShowChangePasswordModal(false)}
                onChangePassword={handleChangePassword}
            />


            {/* Modal do usuwania konta */}
            <DeleteAccountModal
                isOpen={showDeleteAccountModal}
                onClose={() => setShowDeleteAccountModal(false)}
                onDeleteAccount={handleDeleteAccount}
            />

            {/*  Modal do kupowania tokenów --- */}
            {/* <TokenPurchaseModal
                isOpen={showTokenPurchaseModal}
                onClose={() => setShowTokenPurchaseModal(false)}
                onPurchase={handlePurchaseTokens}
            /> */}
        </div>
    );
};

export default Dashboard;
