import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function Payment() {
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const createCheckoutSession = async () => {
            try {
                setLoading(true);
                const planType = searchParams.get("plan");
                const tokens = searchParams.get("tokens") ? parseInt(searchParams.get("tokens")) : null;

                if (!planType && !tokens) {
                    throw new Error("Plan type or tokens amount is required");
                }

                const user = auth.currentUser;
                if (!user) {
                    navigate("/login");
                    return;
                }

                // Określenie kraju na podstawie języka
                const currentLanguage = i18n.language || 'pl';
                let country;

                switch (currentLanguage) {
                    case 'ua':
                        country = "Ukraine";
                        break;
                    case 'pl':
                        country = "Poland";
                        break;
                    default:
                        country = "Germany"; // Dla wszystkich innych języków używamy Niemiec jako reprezentanta strefy euro
                }

                const token = await user.getIdToken();
                const response = await fetch(
                    "https://us-central1-quizonator-a4513.cloudfunctions.net/api/create-checkout-session",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            email: user.email,
                            userId: user.uid,
                            planType,
                            tokens,
                            country // Przekazujemy informację o kraju
                        }),
                    }
                );

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || "Failed to create checkout session");
                }

                const data = await response.json();

                // Bezpośrednio przekieruj do Stripe
                window.location.href = data.url;

            } catch (error) {
                console.error("Error creating checkout session:", error);
                setError(error.message);
                setLoading(false);
            }
        };

        createCheckoutSession();
    }, [searchParams, navigate, t, i18n]);

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-50">
                <Helmet>
                    <title>{t("payment.meta.title") || "Przekierowanie do płatności"}</title>
                </Helmet>
                <div className="text-center">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#00D1A7] mx-auto"></div>
                    <p className="mt-4 text-lg text-gray-700">{t("payment.redirecting") || "Przekierowujemy do płatności..."}</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-50">
                <Helmet>
                    <title>{t("payment.meta.errorTitle") || "Błąd płatności"}</title>
                </Helmet>
                <div className="text-center max-w-md p-6 bg-white rounded-lg shadow-lg">
                    <h1 className="text-2xl font-bold text-red-600 mb-4">{t("payment.errorTitle") || "Błąd podczas tworzenia sesji płatności"}</h1>
                    <p className="text-gray-700 mb-6">{error}</p>
                    <button
                        onClick={() => navigate("/pricing")}
                        className="px-4 py-2 bg-[#1B4965] text-white rounded-lg hover:bg-[#163A50] transition-colors"
                    >
                        {t("payment.backToPricing") || "Wróć do cennika"}
                    </button>
                </div>
            </div>
        );
    }

    return null;
}