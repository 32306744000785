import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { auth, db } from "../firebase"
import { doc, getDoc } from "firebase/firestore"
import { logEvent } from "../analytics"

import {
    Card,
    CardHeader,
    CardTitle,
    CardContent,
    CardFooter,
    CardDescription,
} from "../components/ui/card"
import { Button } from "../components/ui/button"
import { CheckCircle } from "lucide-react"

export default function TokenShop({ country }) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [user, setUser] = useState(null) // Podstawowe dane z auth
    const [userData, setUserData] = useState(null) // Szczegółowe dane z Firestore

    // Ceny dla Polski (PLN)
    const pricesPLN = {
        small: { price: 16.99, originalPrice: 16.99 },
        medium: { price: 36.99, originalPrice: 49.98 },
        large: { price: 84.99, originalPrice: 119.99 }
    }

    // Ceny dla Ukrainy (UAH)
    const pricesUAH = {
        small: { price: 170, originalPrice: 170 },
        medium: { price: 370, originalPrice: 500 },
        large: { price: 850, originalPrice: 1200 }
    }

    // Ceny dla Europy (EUR)
    const pricesEUR = {
        small: { price: 8.99, originalPrice: 8.99 },
        medium: { price: 18.99, originalPrice: 24.99 },
        large: { price: 42.99, originalPrice: 59.99 }
    };

    // Wybierz odpowiednie ceny na podstawie kraju
    const getPricesByCountry = () => {
        if (country === 'Ukraine') {
            return pricesUAH
        } else if (country === 'Poland') {
            return pricesPLN
        } else {
            return pricesEUR
        }
    }

    const prices = getPricesByCountry()

    // Symbole walut i formatowanie
    const getCurrencyByCountry = () => {
        if (country === 'Ukraine') {
            return 'UAH'
        } else if (country === 'Poland') {
            return 'zł'
        } else {
            return '€'
        }
    }

    const currencySymbol = getCurrencyByCountry()

    const formatCurrency = (value) => {
        if (country === 'Ukraine') {
            return `${value.toFixed(0)} ${currencySymbol}`
        } else if (country === 'Poland') {
            return `${value.toFixed(2).replace(".", ",")} ${currencySymbol}`
        } else {
            return `${value.toFixed(2).replace(".", ",")} ${currencySymbol}`
        }
    }

    // Konfigurujemy pakiety
    const packages = [
        {
            id: "small",
            name: t("tokenShop.packages.small.name"),
            answers: 200,
            price: prices.small.price,
            originalPrice: prices.small.originalPrice,
            perks: t("tokenShop.packages.small.perks", { returnObjects: true }),
        },
        {
            id: "medium",
            name: t("tokenShop.packages.medium.name"),
            answers: 600,
            price: prices.medium.price,
            originalPrice: prices.medium.originalPrice,
            perks: t("tokenShop.packages.medium.perks", { returnObjects: true }),
        },
        {
            id: "large",
            name: t("tokenShop.packages.large.name"),
            answers: 1800,
            price: prices.large.price,
            originalPrice: prices.large.originalPrice,
            perks: t("tokenShop.packages.large.perks", { returnObjects: true }),
        },
    ]

    useEffect(() => {
        logEvent("PageView", "TokenShop", t("tokenShop.pageViewEventLabel"))

        // Pobieranie danych użytkownika bezpośrednio z Firestore
        const fetchUserData = async (userEmail) => {
            try {
                // Pobierz dokument użytkownika bezpośrednio z Firestore
                const userDocRef = doc(db, 'users', userEmail)
                const userDocSnap = await getDoc(userDocRef)

                if (userDocSnap.exists()) {
                    setUserData(userDocSnap.data())
                } else {
                    console.log("No such user document!")
                    setUserData(null)
                }
            } catch (error) {
                console.error("Error fetching user data from Firestore:", error)
                setUserData(null)
            }
        }

        // Subskrybuj zmiany stanu autentykacji
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser) // Zapisz obiekt użytkownika z auth

            if (currentUser) {
                fetchUserData(currentUser.email) // Pobierz dodatkowe dane dla zalogowanego użytkownika
            } else {
                setUserData(null)
            }
        })

        return () => unsubscribe()
    }, [t])

    const handlePurchase = async (pkg) => {
        try {
            if (!user) {
                navigate("/register")
                return
            }

            const token = await user.getIdToken()
            const response = await fetch(
                "https://us-central1-quizonator-a4513.cloudfunctions.net/api/create-checkout-session",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        email: user.email,
                        userId: user.uid,
                        planType: "tokens",
                        tokens: pkg.answers,
                        country // Przekazanie country do API
                    }),
                }
            )

            if (!response.ok) {
                const errorData = await response.json()
                throw new Error(errorData.error || "Failed to create checkout session")
            }

            const data = await response.json()
            if (data.url) {
                window.location.href = data.url
            }
        } catch (err) {
            console.error("Error creating checkout session:", err)
            // Możemy dodać wyświetlanie błędu użytkownikowi
            alert(t("tokenShop.checkoutError") || "Wystąpił błąd podczas tworzenia sesji płatności. Spróbuj ponownie.")
        }
    }

    return (
        <>
            <Helmet>
                <title>{t("tokenShop.pageTitle")}</title>
                <meta
                    name="description"
                    content={t("tokenShop.pageMetaDescription")}
                />
            </Helmet>

            <div className="relative bg-gray-50">
                <main className="container px-4 sm:px-6 lg:px-8 py-16 md:py-20 lg:py-14 mx-auto">
                    {/* Nagłówek */}
                    <div className="text-center max-w-3xl mx-auto mb-12">
                        <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-[#1B4965] mb-6">
                            {t("tokenShop.mainHeading")}
                        </h1>
                        <p className="text-lg md:text-xl text-gray-700 mb-6">
                            {t("tokenShop.mainSubHeading")}
                        </p>
                    </div>

                    {/* Karty pakietów */}
                    <div className="grid md:grid-cols-3 gap-8 max-w-7xl mx-auto">
                        {packages.map((pkg) => {
                            const hasDiscount = pkg.originalPrice > pkg.price
                            const discountValue = hasDiscount
                                ? (pkg.originalPrice - pkg.price)
                                : 0

                            return (
                                <Card
                                    key={pkg.id}
                                    className="rounded-3xl shadow-xl border border-gray-200 transition-all duration-300 flex flex-col justify-between h-full"
                                >
                                    <div>
                                        <CardHeader className="text-center pt-8 pb-2">
                                            <CardTitle className="text-3xl font-bold text-[#1B4965] mb-2">
                                                {pkg.name}
                                            </CardTitle>
                                            <CardDescription className="text-lg text-gray-600">
                                                {t("tokenShop.oneTimePurchase")}
                                            </CardDescription>
                                        </CardHeader>
                                        <CardContent className="space-y-6 flex flex-col items-center justify-center mt-6 flex-1">
                                            <div className="flex flex-col items-center">
                                                {/* Cena oryginalna (przekreślona) */}
                                                <div
                                                    className={`text-xl text-gray-500 line-through ${hasDiscount ? "" : "invisible"
                                                        }`}
                                                >
                                                    {formatCurrency(pkg.originalPrice)}
                                                </div>
                                                <div className="text-5xl font-extrabold text-[#00D1A7]">
                                                    {formatCurrency(pkg.price)}
                                                </div>
                                                {/* Informacja o oszczędnościach */}
                                                <div
                                                    className={`text-sm font-semibold mt-1 ${hasDiscount
                                                        ? "text-green-600 animate-pulse"
                                                        : "invisible"
                                                        }`}
                                                >
                                                    {hasDiscount
                                                        ? t("tokenShop.saving", {
                                                            discount: formatCurrency(discountValue),
                                                        })
                                                        : ""}
                                                </div>
                                            </div>

                                            <ul
                                                className={`space-y-3 ${pkg.id === "small" ? "pt-4" : ""
                                                    }`}
                                            >
                                                {pkg.perks.map((perk, i) => (
                                                    <li
                                                        key={i}
                                                        className="flex items-center gap-3 text-gray-700 text-lg"
                                                    >
                                                        <CheckCircle className="w-6 h-6 text-green-500" />
                                                        <span className={i === 0 ? "font-bold" : ""}>
                                                            {perk}
                                                        </span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </CardContent>
                                    </div>
                                    <CardFooter className="px-8 pb-8">
                                        <Button
                                            size="lg"
                                            className="w-full py-3 rounded-lg shadow font-semibold bg-gradient-to-r from-[#00D1A7] to-[#00B594] text-white hover:opacity-90 transition-all duration-300"
                                            onClick={() => handlePurchase(pkg)}
                                        >
                                            {t("tokenShop.buyNow")}
                                        </Button>
                                        <p className="mt-4 text-xs text-gray-600 text-center">
                                            {t("tokenShop.footnote")}
                                        </p>
                                    </CardFooter>
                                </Card>
                            )
                        })}
                    </div>
                </main>
            </div>
        </>
    )
}