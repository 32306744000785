// import React from 'react';
// import { useTranslation } from "react-i18next";

// const ReferralProgramRegulation = () => {
//     const { t } = useTranslation();

//     const sections = [
//         {
//             title: t('referralProgramRegulation.section1.title'),
//             description: t('referralProgramRegulation.section1.description'),
//         },
//         {
//             title: t('referralProgramRegulation.section2.title'),
//             description: t('referralProgramRegulation.section2.description'),
//         },
//         {
//             title: t('referralProgramRegulation.section3.title'),
//             description: t('referralProgramRegulation.section3.description'),
//             subsections: [
//                 {
//                     title: t('referralProgramRegulation.section3.sub1.title'),
//                     description: t('referralProgramRegulation.section3.sub1.description'),
//                 },
//                 {
//                     title: t('referralProgramRegulation.section3.sub2.title'),
//                     description: t('referralProgramRegulation.section3.sub2.description'),
//                 },
//             ],
//         },
//         {
//             title: t('referralProgramRegulation.section4.title'),
//             description: t('referralProgramRegulation.section4.description'),
//             subsections: [
//                 {
//                     title: t('referralProgramRegulation.section4.sub1.title'),
//                     description: t('referralProgramRegulation.section4.sub1.description'),
//                 },
//                 {
//                     title: t('referralProgramRegulation.section4.sub2.title'),
//                     description: t('referralProgramRegulation.section4.sub2.description'),
//                 },
//             ],
//         },
//         {
//             title: t('referralProgramRegulation.section5.title'),
//             description: t('referralProgramRegulation.section5.description'),
//         },
//         {
//             title: t('referralProgramRegulation.section6.title'),
//             description: t('referralProgramRegulation.section6.description'),
//         },
//         {
//             title: t('referralProgramRegulation.section7.title'),
//             description: t('referralProgramRegulation.section7.description'),
//         },
//         {
//             title: t('referralProgramRegulation.section8.title'),
//             description: t('referralProgramRegulation.section8.description'),
//         },
//         {
//             title: t('referralProgramRegulation.section9.title'),
//             description: t('referralProgramRegulation.section9.description'),
//         },
//         {
//             title: t('referralProgramRegulation.section10.title'),
//             description: t('referralProgramRegulation.section10.description'),
//         },
//         {
//             title: t('referralProgramRegulation.section11.title'),
//             description: t('referralProgramRegulation.section11.description'),
//         },
//         {
//             title: t('referralProgramRegulation.section12.title'),
//             description: t('referralProgramRegulation.section12.description'),
//         },
//         {
//             title: t('referralProgramRegulation.section13.title'),
//             description: t('referralProgramRegulation.section13.description'),
//         },
//         {
//             title: t('referralProgramRegulation.section14.title'),
//             description: t('referralProgramRegulation.section14.description'),
//         },
//     ];

//     return (
//         <div className="min-h-screen p-8 bg-white text-gray-800">
//             <div className="container mx-auto text-left rounded-lg p-6">
//                 <h1 className="text-4xl font-bold mb-6">{t('referralProgramRegulation.mainTitle')}</h1>
//                 <p className="mb-6 text-lg">{t('referralProgramRegulation.lastUpdate')}</p>

//                 {sections.map((section, index) => (
//                     <section key={index} className="mb-8">
//                         <h2 className="text-2xl font-semibold mb-4">{section.title}</h2>
//                         {section.description && (
//                             <p className="mb-4 whitespace-pre-line">{section.description}</p>
//                         )}
//                         {section.subsections &&
//                             section.subsections.map((subsection, subIndex) => (
//                                 <div key={subIndex}>
//                                     <h3 className="text-xl font-semibold mb-2">{subsection.title}</h3>
//                                     <p className="mb-4 whitespace-pre-line">{subsection.description}</p>
//                                 </div>
//                             ))}
//                     </section>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default ReferralProgramRegulation;
import React from 'react';
import { useTranslation } from "react-i18next";

const ReferralProgramRegulation = () => {
    const { t } = useTranslation();

    const sections = [
        {
            title: t('referralProgramRegulation.section1.title'),
            description: t('referralProgramRegulation.section1.description'),
        },
        {
            title: t('referralProgramRegulation.section2.title'),
            description: t('referralProgramRegulation.section2.description'),
        },
        {
            title: t('referralProgramRegulation.section3.title'),
            description: t('referralProgramRegulation.section3.description'),
        },
        {
            title: t('referralProgramRegulation.section4.title'),
            description: t('referralProgramRegulation.section4.description'),
        },
        {
            title: t('referralProgramRegulation.section5.title'),
            description: t('referralProgramRegulation.section5.description'),
        },
        {
            title: t('referralProgramRegulation.section6.title'),
            description: t('referralProgramRegulation.section6.description'),
        },
        {
            title: t('referralProgramRegulation.section7.title'),
            description: t('referralProgramRegulation.section7.description'),
        },
        {
            title: t('referralProgramRegulation.section8.title'),
            description: t('referralProgramRegulation.section8.description'),
        },
        {
            title: t('referralProgramRegulation.section9.title'),
            description: t('referralProgramRegulation.section9.description'),
            rights: [
                t('referralProgramRegulation.section9.rights1'),
                t('referralProgramRegulation.section9.rights2'),
                t('referralProgramRegulation.section9.rights3'),
                t('referralProgramRegulation.section9.rights4'),
                t('referralProgramRegulation.section9.rights5'),
                t('referralProgramRegulation.section9.rights6'),
                t('referralProgramRegulation.section9.rights7'),
            ],
        },
        {
            title: t('referralProgramRegulation.section10.title'),
            description: t('referralProgramRegulation.section10.description'),
        },
        {
            title: t('referralProgramRegulation.section11.title'),
            description: t('referralProgramRegulation.section11.description'),
        },
        {
            title: t('referralProgramRegulation.section12.title'),
            description: t('referralProgramRegulation.section12.description'),
        },
        {
            title: t('referralProgramRegulation.section13.title'),
            description: t('referralProgramRegulation.section13.description'),
        },
    ];

    return (
        <div className="min-h-screen p-8 bg-white text-gray-800">
            <div className="container mx-auto text-left rounded-lg p-6">
                <h1 className="text-4xl font-bold mb-6">{t('referralProgramRegulation.mainTitle')}</h1>
                <p className="mb-6 text-lg">{t('referralProgramRegulation.lastUpdate')}</p>

                {sections.map((section, index) => (
                    <section key={index} className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">{section.title}</h2>
                        {section.description && (
                            <p className="mb-4 whitespace-pre-line">{section.description}</p>
                        )}
                        {section.rights && (
                            <ul className="list-disc ml-8">
                                {section.rights.map((right, rightIndex) => (
                                    <li key={rightIndex} className="mb-2">
                                        {right}
                                    </li>
                                ))}
                            </ul>
                        )}
                        {section.contact && <p className="mb-4">{section.contact}</p>}
                    </section>
                ))}
            </div>
        </div>
    );
};

export default ReferralProgramRegulation;