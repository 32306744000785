import React from "react";

/**
 * Główny kontener Dialogu (modal). 
 * Oczekuje, że rodzic przekazuje:
 *  - open (boolean) => czy modal jest otwarty
 *  - onOpenChange (funkcja) => wywoływana przy zamknięciu
 *  - children => zawartość modala (np. DialogContent)
 */
export function Dialog({ open, onOpenChange, children }) {
    if (!open) return null;

    // Kliknięcie tła (backdrop) powoduje zamknięcie modala
    const handleBackdropClick = () => {
        onOpenChange(false);
    };

    // Kliknięcie wewnątrz “okienka” – zatrzymuje propagację, by nie zamknąć modala
    const handleDialogClick = (event) => {
        event.stopPropagation();
    };

    return (
        <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
            onClick={handleBackdropClick}
        >
            <div
                className="bg-white rounded-lg p-6 w-full max-w-md shadow-lg"
                onClick={handleDialogClick}
            >
                {children}
            </div>
        </div>
    );
}

/**
 * Komponent, który ma wywołać otwarcie Dialogu po kliknięciu.
 * Oczekuje:
 *  - onOpenChange (funkcja) => np. setOpen
 *  - children => np. tekst / ikona / cokolwiek w środku przycisku
 */
export function DialogTrigger({ onOpenChange, children }) {
    return (
        <button
            onClick={() => onOpenChange(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
            {children}
        </button>
    );
}

/**
 * Zawartość (treść) modala.
 * Możesz użyć go w środku <Dialog>... 
 */
export function DialogContent({ children }) {
    return <div className="space-y-4">{children}</div>;
}

/**
 * Nagłówek modala (np. z tytułem).
 */
export function DialogHeader({ children }) {
    return <div className="border-b pb-4 mb-4">{children}</div>;
}

/**
 * Tytuł w nagłówku.
 */
export function DialogTitle({ children }) {
    return <h2 className="text-xl font-bold">{children}</h2>;
}

/**
 * Opis w nagłówku (np. krótkie info co robi dialog).
 */
export function DialogDescription({ children }) {
    return <p className="text-sm text-gray-600">{children}</p>;
}

export const DialogFooter = ({ className, ...props }) => (
    <div
        className={`flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2 ${className}`}
        {...props}
    />
)

