import { Button } from '../components/ui/button';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../assets/logo.png';
import { auth, db } from '../firebase'; // <-- Upewnij się, że masz eksport db w pliku firebase.js
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, onSnapshot, setDoc, updateDoc } from 'firebase/firestore'; // <-- [NOWE] updateDoc potrzebne
import { Menu, X, Globe, DollarSign } from 'lucide-react';
import { FaInstagram, FaTiktok, FaSquareXTwitter } from "react-icons/fa6";
import { useLocation } from 'react-router-dom';
import { Home, Star, Info, HelpCircle, MessageSquare, BookOpen, Phone } from 'lucide-react';
import { logEvent } from '../analytics';
import { PromotionPopup } from "./PromoModal";
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import NewsletterPopup from './Newsletter/Unauthorized';
import ConfirmationPopup from './Newsletter/Authorized';
import ThankYouPopup from './Newsletter/ThankYouPopup';

import axios from 'axios';

export default function Layout({ children, country }) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const languageMenuRef = useRef(null);

    // -----------------------------
    // STANY UŻYTKOWNIKA
    // -----------------------------
    const [user, setUser] = useState(null);
    const [userDoc, setUserDoc] = useState(null);
    const [authReady, setAuthReady] = useState(false);

    // -----------------------------
    // STANY DODATKOWE
    // -----------------------------
    const [isNavOpen, setIsNavOpen] = useState(false);

    // Newsletter
    const [showNewsletterPopup, setShowNewsletterPopup] = useState(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [showThankYouPopup, setShowThankYouPopup] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false);

    // Promocyjny popup
    const [showPopup, setShowPopup] = useState(false);

    // Menu języków
    const [showLangMenu, setShowLangMenu] = useState(false);

    const languages = useMemo(() => [
        { code: 'pl', label: 'PL' },
        { code: 'en', label: 'EN' },
        { code: 'ru', label: 'RU' },
        { code: 'ua', label: 'UA' },
    ], []);

    // -----------------------------
    // 1. Pobieranie roli i danych z Firestore
    // -----------------------------
    useEffect(() => {
        let unsubscribeDoc = () => { };
        const unsubscribeAuth = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                const userRef = doc(db, 'users', currentUser.email);
                unsubscribeDoc = onSnapshot(userRef, (docSnap) => {
                    if (docSnap.exists()) {
                        setUserDoc(docSnap.data());
                    } else {
                        setUserDoc(null);
                    }
                });

                try {
                    const alreadySubscribed = await checkSubscriptionStatus(currentUser.email);
                    setIsSubscribed(alreadySubscribed);
                } catch (error) {
                    console.error("Błąd przy sprawdzaniu subskrypcji:", error);
                }
            } else {
                setUser(null);
                setUserDoc(null);
                setIsSubscribed(false);
            }
            setAuthReady(true);
        });

        return () => {
            unsubscribeAuth();
            unsubscribeDoc();
        };
    }, []);

    // -----------------------------
    // 2. Funkcja sprawdzająca subskrypcję
    // -----------------------------
    const checkSubscriptionStatus = async (email) => {
        if (!email) return false;
        try {
            const { data } = await axios.get(
                `https://us-central1-quizonator-a4513.cloudfunctions.net/api/newsletter-check/${encodeURIComponent(email)}`
            );
            return data.subscribed;
        } catch (error) {
            console.error("Błąd przy sprawdzaniu subskrypcji w MailerLite:", error);
            return false;
        }
    };

    // -----------------------------
    // 3. Subskrypcja do newslettera
    // -----------------------------
    const subscribeToNewsletter = async (email) => {
        try {
            await axios.post(
                'https://us-central1-quizonator-a4513.cloudfunctions.net/api/newsletter-subscribe',
                { email },
                { headers: { 'Content-Type': 'application/json' } }
            );
            setIsSubscribed(true);
            setShowThankYouPopup(true);
        } catch (error) {
            console.error('Błąd przy zapisywaniu do newslettera:', error);
        }
    };

    // -----------------------------
    // 4. Wylogowanie
    // -----------------------------
    const handleLogout = async () => {
        try {
            await signOut(auth);
            setUser(null);
            setUserDoc(null);
            setIsSubscribed(false);
            navigate('/login', { replace: true });
        } catch (error) {
            console.error('Błąd podczas wylogowywania:', error);
        }
    };

    // -----------------------------
    // 5. Opóźnione wyświetlanie popupów
    // -----------------------------
    useEffect(() => {
        if (!authReady) return;

        const excludedPaths = [
            "/reset-password",
            "/verify-email",
            "/register",
            "/login",
            "/forgot-password",
            "/__/auth/action",
        ];

        if (excludedPaths.some(path => location.pathname.startsWith(path))) {
            return;
        }

        const popupShownNewsletter = sessionStorage.getItem("newsletterPopupShown");
        const popupShownConfirmation = sessionStorage.getItem("confirmationPopupShown");
        const POPUP_DELAY = 3000; // np. 3 sekundy

        const timer = setTimeout(() => {
            if (!user && !popupShownNewsletter) {
                // Niezalogowany
                setShowNewsletterPopup(true);
                sessionStorage.setItem("newsletterPopupShown", "true");
                logEvent("Popup", "Show", "Newsletter Popup");
            } else if (user && !isSubscribed && !popupShownConfirmation) {
                // Zalogowany, ale nie subskrybowany
                setShowConfirmationPopup(true);
                sessionStorage.setItem("confirmationPopupShown", "true");
                logEvent("Popup", "Show", "Confirmation Popup");
            }
        }, POPUP_DELAY);

        return () => clearTimeout(timer);
    }, [authReady, user, location.pathname, isSubscribed]);

    // -----------------------------
    // 6. Obsługa klikania w linki
    // -----------------------------
    const handleLinkClick = (path) => {
        navigate(path, { replace: true });
        setIsNavOpen(false);
    };

    // -----------------------------
    // 7. Zamykanie nav po kliknięciu poza
    // -----------------------------
    useEffect(() => {
        const handleOutsideClick = (event) => {
            const navMenu = document.querySelector('nav');
            if (isNavOpen && navMenu && !navMenu.contains(event.target)) {
                setIsNavOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isNavOpen]);

    // -----------------------------
    // 8. Zmiana języka
    // -----------------------------
    // [NOWE] Funkcja, która dodatkowo aktualizuje Firestore, jeżeli user jest zalogowany
    const changeLanguage = async (lng) => {
        i18n.changeLanguage(lng);
        setShowLangMenu(false);
        localStorage.setItem('preferredLanguage', lng);
        if (user) {
            try {
                const userRef = doc(db, 'users', user.email);
                // Dokonujemy merge, żeby nie nadpisywać innych pól
                await updateDoc(userRef, { uiLanguage: lng });
            } catch (err) {
                console.error("Błąd przy aktualizacji języka w Firestore:", err);
            }
        }
    };

    // [NOWE] Gdy mamy userDoc, sprawdzamy, czy istnieje w nim pole uiLanguage
    //       i ustawiamy je jako priorytet (ale tylko, gdy authReady).
    useEffect(() => {
        if (!authReady) return;

        // 1. Jeśli userDoc ma uiLanguage (Firebase) => najwyższy priorytet.
        if (userDoc?.uiLanguage) {
            if (i18n.language !== userDoc.uiLanguage) {
                i18n.changeLanguage(userDoc.uiLanguage);
            }
            return;
        }

        // 2. Jeśli mamy ZAPISANY język w localStorage (ręcznie wybrany przez usera)
        const storedLang = localStorage.getItem('preferredLanguage');
        if (storedLang) {
            if (i18n.language !== storedLang) {
                i18n.changeLanguage(storedLang);
            }
            return;
        }

        // 3. Jeśli mamy `country` (np. "PL", "UA", itp.),
        //    to ustawiamy np. "pl" dla Polski, w pozostałych przypadkach "en".
        if (country) {
            if (country === 'Poland') {
                i18n.changeLanguage('pl');
            } else if (country === 'Ukraine') {
                i18n.changeLanguage('ua');
            } else if (country === 'Russia') {
                i18n.changeLanguage('ru');
            } else {
                i18n.changeLanguage('en');
            }
            return;
        }

        // 4. Jeśli NIC z powyższych nie zadziałało, bierzemy język przeglądarki,
        //    ale tylko jeśli go mamy w Twojej liście obsługiwanych języków.
        let browserLang = navigator.language.split('-')[0];
        if (!languages.some((l) => l.code === browserLang)) {
            browserLang = 'pl'; // fallback
        }
        if (i18n.language !== browserLang) {
            i18n.changeLanguage(browserLang);
        }
    }, [authReady, userDoc, i18n, country, languages]);



    // -----------------------------
    // Linki w menu nawigacji
    // -----------------------------
    const navItems = [
        { name: t('navbar.home'), icon: <Home className="w-5 h-5 mr-2 lg:hidden" />, path: "/" },
        { name: t('navbar.advantages'), icon: <Star className="w-5 h-5 mr-2 lg:hidden" />, path: "/zalety" },
        { name: t('navbar.pricing'), icon: <DollarSign className="w-5 h-5 mr-2 lg:hidden" />, path: "/cennik" },
        { name: t('navbar.about'), icon: <Info className="w-5 h-5 mr-2 lg:hidden" />, path: "/o-nas" },
        { name: t('navbar.howItWorks'), icon: <HelpCircle className="w-5 h-5 mr-2 lg:hidden" />, path: "/jak-to-dziala" },
        { name: t('navbar.reviews'), icon: <MessageSquare className="w-5 h-5 mr-2 lg:hidden" />, path: "/opinie" },
        { name: t('navbar.faq'), icon: <BookOpen className="w-5 h-5 mr-2 lg:hidden" />, path: "/faq" },
        { name: t('navbar.contact'), icon: <Phone className="w-5 h-5 mr-2 lg:hidden" />, path: "/kontakt" },
    ];

    // -----------------------------
    // Przygotowanie przycisku zależnego od roli
    // -----------------------------
    const isAmbassador = userDoc?.role === 'ambassador';
    const roleButton = (
        <Button
            onClick={() => {
                if (isAmbassador) {
                    handleLinkClick('/panel-promotora');
                    logEvent('Navigation', 'Click', 'Promotor Panel');
                } else {
                    handleLinkClick('/zarabiaj');
                    logEvent('Navigation', 'Click', 'Earn');
                }
            }}
            className="
        relative
        text-sm px-4 py-2
        bg-white text-green-600
        border border-green-500
        hover:bg-green-50
        transition-colors
        w-full lg:w-auto
        flex items-center justify-center
      "
            style={{
                boxShadow: '0 0 8px 2px rgba(34,197,94,0.3)' // zielona poświata
            }}
        >
            <DollarSign className="w-4 h-4 mr-2" />
            {isAmbassador ? t('navbar.promotorPanel') : t('navbar.earn')}
        </Button>
    );

    return (
        <div className="min-h-screen bg-gradient-to-b from-[#F0F8FF] to-[#E6ECF8] text-[#1B4965] font-sans">
            {/* Promocyjny popup */}
            <PromotionPopup showPopup={showPopup} handleClose={() => setShowPopup(false)} />

            {/* Popup newsletterowy - niezalogowani */}
            <NewsletterPopup
                showPopup={showNewsletterPopup}
                handleClose={() => setShowNewsletterPopup(false)}
                onSubscribe={(email) => {
                    subscribeToNewsletter(email);
                    setShowNewsletterPopup(false);
                }}
            />

            {/* Popup newsletterowy - zalogowani */}
            <ConfirmationPopup
                showPopup={showConfirmationPopup}
                handleClose={() => setShowConfirmationPopup(false)}
                onSubscribe={() => {
                    if (user?.email) {
                        subscribeToNewsletter(user.email);
                    }
                    setShowConfirmationPopup(false);
                }}
            />

            {/* Podziękowanie */}
            <ThankYouPopup
                show={showThankYouPopup}
                setShowThankYouPopup={setShowThankYouPopup}
            />

            {/* Header */}
            <header className="sticky top-0 z-40 w-full border-b bg-white">
                <div className="container mx-auto flex items-center justify-between px-4 md:px-10 max-w-screen-xl h-16">
                    {/* Logo */}
                    <Link to="/" className="flex items-center space-x-2">
                        <img src={Logo} alt="Logo Quizonator" className="h-8 w-8" />
                        <span className="font-bold text-lg md:text-xl text-[#1B4965]">Quizonator</span>
                    </Link>

                    <div className="flex items-center space-x-4">
                        {/* Toggle Button for Mobile */}
                        <button
                            className="lg:hidden text-gray-600"
                            onClick={() => setIsNavOpen(!isNavOpen)}
                            aria-label="Toggle navigation"
                        >
                            {isNavOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
                        </button>
                    </div>

                    {/* Navigation Links */}
                    <nav
                        className={`
              ${isNavOpen ? "block" : "hidden"}
              absolute top-16 left-0 w-full bg-white
              lg:static lg:flex lg:items-center lg:justify-between lg:w-auto
              shadow-md lg:shadow-none overflow-visible
            `}
                    >
                        {/* Lista linków głównych (ul) */}
                        <ul className="flex flex-col text-center lg:flex-row lg:space-x-6">
                            {navItems.map((item, index) => {
                                const isActive = location.pathname === item.path;
                                return (
                                    <li key={index} className="group">
                                        <Button
                                            onClick={() => handleLinkClick(item.path)}
                                            className={`
                        block px-6 py-3 lg:p-0 text-sm font-medium transition-colors rounded-md
                        ${isActive
                                                    ? "text-[#00D1A7] font-bold"
                                                    : "text-gray-700 hover:text-[#00D1A7]"
                                                }
                      `}
                                        >
                                            <span>{item.icon}</span>
                                            {item.name}
                                        </Button>
                                    </li>
                                );
                            })}
                        </ul>

                        {/* Sekcja CTA + przełącznik języka + logowanie/rejestracja/wylogowanie */}
                        <div
                            className="
                flex flex-col lg:flex-row
                lg:items-center lg:space-x-4
                my-4 lg:my-0
                space-y-4 lg:space-y-0
                px-6 lg:px-0
                lg:ml-auto
              "
                        >
                            {/* Przełącznik języka */}
                            <div className="relative flex items-center justify-center">
                                <button
                                    className="text-sm px-4 py-2 rounded-lg bg-white text-gray-500 hover:text-[#00D1A7]
                             w-full lg:w-auto flex items-center space-x-2 justify-center"
                                    onClick={() => setShowLangMenu(!showLangMenu)}
                                    aria-label="Change language"
                                >
                                    <Globe className="w-5 h-5" />
                                    {/* <span className="font-medium">{t('navbar.language')}</span> */}
                                </button>
                                {showLangMenu && (
                                    <div
                                        ref={languageMenuRef}
                                        className="absolute z-50 top-full mt-1 w-24 bg-white border shadow-md rounded"
                                    >
                                        {languages.map((lng) => (
                                            <button
                                                key={lng.code}
                                                className={`
                          block w-full text-left px-4 py-2 text-sm hover:bg-gray-100
                          ${i18n.language === lng.code ? 'font-bold' : ''}
                        `}
                                                onClick={() => changeLanguage(lng.code)}
                                            >
                                                {lng.label}
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </div>

                            {/* Przycisk zależny od roli (Firestore) */}
                            {roleButton}

                            {/* Dalej - logowanie / wylogowanie / itp. */}
                            {user ? (
                                <>
                                    <Button
                                        onClick={() => {
                                            handleLinkClick('/dashboard');
                                            logEvent('Navigation', 'Click', 'Dashboard');
                                        }}
                                        className="text-sm px-4 py-2 bg-white text-gray-500 border border-gray-500 hover:bg-gray-100 transition-colors w-full lg:w-auto"
                                    >
                                        {t('navbar.dashboard')}
                                    </Button>

                                    <Button
                                        className="text-sm px-4 py-2 bg-red-500 text-white hover:bg-red-600 transition-colors w-full lg:w-auto"
                                        onClick={handleLogout}
                                    >
                                        {t('navbar.logout')}
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button
                                        onClick={() => {
                                            handleLinkClick('/login');
                                            logEvent('Navigation', 'Click', 'Login');
                                        }}
                                        className="text-sm px-4 py-2 bg-white text-gray-500 border border-gray-500 hover:bg-gray-100 transition-colors w-full lg:w-auto"
                                    >
                                        {t('navbar.login')}
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            handleLinkClick('/cennik');
                                            logEvent('Navigation', 'Click', 'Register');
                                        }}
                                        className="text-sm px-4 py-2 bg-[#00D1A7] text-white hover:bg-[#00B594] transition-colors w-full lg:w-auto"
                                    >
                                        {t('navbar.start')}
                                    </Button>
                                </>
                            )}
                        </div>
                    </nav>
                </div>
            </header>

            {/* Main Content */}
            <main>{children}</main>

            {/* Footer */}
            <footer className="bg-gradient-to-t from-[#F0F8FF] to-white border-t">
                <div className="container mx-auto py-8 pb-16 md:py-6 flex flex-col md:flex-row justify-between items-center px-6 md:px-10 text-sm text-gray-600 space-y-4 md:space-y-0">
                    {/* Logo i tekst */}
                    <div className="flex flex-row items-center space-x-2 md:space-y-0 md:space-x-4 text-center md:text-left">
                        <img src={Logo} alt="Logo Quizonator" className="h-6 w-6" />
                        <span>2025 Quizonator. {t('footer.allRightsReserved')}</span>
                    </div>

                    {/* Ikony mediów społecznościowych */}
                    <div className="flex flex-row items-center space-x-2 md:space-y-0 md:space-x-4">
                        <span className="font-medium">{t('footer.followUs')}</span>
                        <a
                            href="https://www.instagram.com/quizonator/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:text-[#00D1A7]"
                        >
                            <FaInstagram className="w-5 h-5" />
                        </a>
                        <a
                            href="https://www.tiktok.com/@quizonatorai"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:text-[#00D1A7]"
                        >
                            <FaTiktok className="w-5 h-5" />
                        </a>
                        <a
                            href="https://x.com/quizonator"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:text-[#00D1A7]"
                        >
                            <FaSquareXTwitter className="w-5 h-5" />
                        </a>
                    </div>

                    {/* Linki w stopce */}
                    <nav className="flex flex-wrap justify-center md:justify-start space-x-4">
                        <Link
                            to="/polityka-prywatnosci"
                            className="hover:underline"
                            onClick={() => handleLinkClick('/polityka-prywatnosci')}
                        >
                            {t('footer.privacyPolicy')}
                        </Link>
                        <Link
                            to="/warunki-uzytkowania"
                            className="hover:underline"
                            onClick={() => handleLinkClick('/warunki-uzytkowania')}
                        >
                            {t('footer.termsOfUse')}
                        </Link>
                        <Link
                            to="/polityka-cookies"
                            className="hover:underline"
                            onClick={() => handleLinkClick('/polityka-cookies')}
                        >
                            {t('footer.cookiePolicy')}
                        </Link>
                        <Link
                            to="/polityka-platnosci"
                            className="hover:underline"
                            onClick={() => handleLinkClick('/polityka-platnosci')}
                        >
                            {t('footer.paymentPolicy')}
                        </Link>
                        <Link
                            to="/program-referencyjny"
                            className="hover:underline"
                            onClick={() => handleLinkClick('/program-referencyjny')}
                        >
                            {t('footer.referralProgram')}
                        </Link>
                        <Link
                            to="/kontakt"
                            className="hover:underline"
                            onClick={() => handleLinkClick('/kontakt')}
                        >
                            {t('footer.contact')}
                        </Link>
                    </nav>
                </div>
            </footer>
        </div>
    );
}
