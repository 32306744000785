import { Button } from "../components/ui/button";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../components/ui/card";
import { BrainCircuit, FileQuestion, EyeOff, Star } from 'lucide-react';
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import DefaultModeGif from '../assets/defmode.gif';
import MoodleLogo from '../assets/moodle.png';
import MicrosoftForms from '../assets/microsoft-forms.png';
import TestPortalLogo from '../assets/testportal.png';
import Quizziz from '../assets/quizziz.png';
import { logEvent } from '../analytics';
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";
import CompatibleInstitutionsSection from '../components/compatibleInstitutions'
import PromoSection from "../components/PromoSections";
export default function Home() {
    const { t } = useTranslation();

    useEffect(() => {
        logEvent('PageView', 'Home', t('home.logEventPageView'));
    }, [t]);

    const features = [
        {
            icon: <BrainCircuit className="h-12 w-12 mb-4 text-[#00D1A7]" />,
            title: t('home.features.feature1.title'),
            description: t('home.features.feature1.description'),
        },
        {
            icon: <FileQuestion className="h-12 w-12 mb-4 text-[#2196F3]" />,
            title: t('home.features.feature2.title'),
            description: t('home.features.feature2.description'),
        },
        {
            icon: <EyeOff className="h-12 w-12 mb-4 text-[#00D1A7]" />,
            title: t('home.features.feature3.title'),
            description: t('home.features.feature3.description'),
        },
    ];

    const testimonials = [
        {
            name: t('home.testimonials.testimonial1.name'),
            school: t('home.testimonials.testimonial1.school'),
            quote: t('home.testimonials.testimonial1.quote'),
            rating: 5,
        },
        {
            name: t('home.testimonials.testimonial2.name'),
            school: t('home.testimonials.testimonial2.school'),
            quote: t('home.testimonials.testimonial2.quote'),
            rating: 5,
        },
        {
            name: t('home.testimonials.testimonial3.name'),
            school: t('home.testimonials.testimonial3.school'),
            quote: t('home.testimonials.testimonial3.quote'),
            rating: 5,
        },
    ];

    const faqs = [
        {
            question: t('home.faq.shortFaq1.question'),
            answer: t('home.faq.shortFaq1.answer'),
        },
        {
            question: t('home.faq.shortFaq2.question'),
            answer: t('home.faq.shortFaq2.answer'),
        },
        {
            question: t('home.faq.shortFaq3.question'),
            answer: t('home.faq.shortFaq3.answer'),
        },
    ];

    return (
        <>
            <Helmet>
                <title>{t('home.seo.title')}</title>
                <meta
                    name="description"
                    content={t('home.seo.description')}
                />
                <meta name="keywords" content={t('home.seo.keywords')} />

                <meta property="og:title" content={t('home.seo.ogTitle')} />
                <meta
                    property="og:description"
                    content={t('home.seo.ogDescription')}
                />
                <meta property="og:image" content={t('home.seo.ogImage')} />
                <meta property="og:url" content={t('home.seo.ogUrl')} />
                <meta property="og:type" content="website" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={t('home.seo.twitterTitle')} />
                <meta
                    name="twitter:description"
                    content={t('home.seo.twitterDescription')}
                />
                <meta name="twitter:image" content={t('home.seo.twitterImage')} />
            </Helmet>

            {/* Hero Section */}
            <section className="py-20 md:py-32">
                <div className="container mx-auto px-4 md:px-6">
                    <div className="flex flex-col lg:flex-row items-center justify-between gap-12">
                        <div className="lg:w-1/2 space-y-8">
                            <h1 className="text-5xl md:text-6xl font-bold tracking-tighter text-[#1B4965]">
                                {t('home.hero.title')}
                            </h1>
                            <p className="text-xl text-gray-600 max-w-lg">
                                {t('home.hero.description')}
                            </p>

                            <div className="flex flex-col sm:flex-row gap-4">
                                <Link to="/cennik">
                                    <Button
                                        size="lg"
                                        className="text-lg bg-[#00D1A7] hover:bg-[#00B594] text-white"
                                        onClick={() => {
                                            logEvent('Home', 'Click', t('home.hero.startTrial'));
                                        }}
                                    >
                                        {t('home.hero.startTrial')}
                                    </Button>
                                </Link>
                                <Link to="/jak-to-dziala">
                                    <Button
                                        size="lg"
                                        variant="outline"
                                        className="text-lg border-[#1B4965] text-[#1B4965] hover:bg-[#1B4965] hover:text-white"
                                        onClick={() => {
                                            logEvent('Home', 'Click', t('home.hero.learnMore'));
                                        }}
                                    >
                                        {t('home.hero.learnMore')}
                                    </Button>
                                </Link>
                            </div>
                        </div>

                        <div className="lg:w-1/2">
                            <img
                                src={DefaultModeGif}
                                alt={t('home.hero.imageAlt')}
                                width={600}
                                height={400}
                                className="rounded-lg shadow-2xl"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-12 bg-white">
                <div className="container mx-auto px-4 md:px-6">
                    <h2 className="text-3xl md:text-4xl font-bold text-center mb-6 text-[#1B4965]">
                        {t('home.compatiblePlatforms.title')}
                    </h2>

                    <div className="flex flex-col sm:flex-row items-center justify-center gap-12">
                        <div className="flex flex-col items-center justify-center gap-6">
                            <div className="p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition duration-300 border border-[#E7F5FF]">
                                <img
                                    src={MoodleLogo}
                                    alt={t('home.compatiblePlatforms.moodleAlt')}
                                    className="w-36 h-20 object-contain"
                                />
                            </div>
                            <h3 className="text-lg font-semibold text-[#1B4965]">{t('home.compatiblePlatforms.moodleName')}</h3>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-6">
                            <div className="p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition duration-300 border border-[#E7F5FF]">
                                <img
                                    src={TestPortalLogo}
                                    alt={t('home.compatiblePlatforms.testportalAlt')}
                                    className="w-36 h-20 object-contain"
                                />
                            </div>
                            <h3 className="text-lg font-semibold text-[#1B4965]">{t('home.compatiblePlatforms.testportalName')}</h3>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-6">
                            <div className="p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition duration-300 border border-[#E7F5FF]">
                                <img
                                    src={MicrosoftForms}
                                    alt={t('home.compatiblePlatforms.microsoftformsAlt')}
                                    className="w-36 h-20 object-contain"
                                />
                            </div>
                            <h3 className="text-lg font-semibold text-[#1B4965]">{t('home.compatiblePlatforms.microsoftformsName')}</h3>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-6 relative">
                            <div className="relative p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition duration-300 border border-[#E7F5FF]">
                                <img
                                    src={Quizziz}
                                    alt={t('home.compatiblePlatforms.quizzizAlt')}
                                    className="w-36 h-20 object-contain"
                                />
                                <span className="absolute top-0 right-0 bg-red-500 text-white text-xs font-bold px-2 py-0.5 rounded-bl">
                                  {t('home.compatiblePlatforms.newplatform')}
                                </span>
                            </div>
                            <h3 className="text-lg font-semibold text-[#1B4965]">{t('home.compatiblePlatforms.quizzizName')}</h3>
                        </div>
                    </div>

                    <div className="mt-12 text-center">
                        <p className="text-lg text-gray-700 mb-4">
                            {t('home.compatiblePlatforms.suggestionText')}
                        </p>
                        <Link to="/kontakt">
                            <Button
                                size="lg"
                                className="bg-[#00D1A7] hover:bg-[#00B594] text-white px-6 py-3 rounded-lg"
                                onClick={() => logEvent('Home', 'Click', t('home.compatiblePlatforms.suggestButton'))}
                            >
                                {t('home.compatiblePlatforms.suggestButton')}
                            </Button>
                        </Link>
                    </div>

                    <div className="text-xs text-gray-400 text-center mt-8 max-w-2xl mx-auto">
                        <p>
                            {t('home.compatiblePlatforms.disclaimer')}
                        </p>
                    </div>
                </div>
            </section>

            {/* Promo Section */}
            <PromoSection t={t} />

            <CompatibleInstitutionsSection />

            {/* Features Section */}
            <section className="py-20 bg-[#F8FAFC]">
                <div className="container mx-auto px-4 md:px-6">
                    <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-[#1B4965]">
                        {t('home.features.title')}
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {features.map((feature, index) => (
                            <Card key={index} className="text-center bg-white shadow-lg hover:shadow-xl transition-shadow duration-300">
                                <CardHeader>
                                    <div className="flex justify-center">{feature.icon}</div>
                                    <CardTitle className="text-xl text-[#1B4965] font-semibold">{feature.title}</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p className="text-gray-600">{feature.description}</p>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </div>
            </section>

            {/* Testimonials Section */}
            <section className="py-20">
                <div className="container mx-auto px-4 md:px-6">
                    <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-[#1B4965]">
                        {t('home.testimonials.title')}
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {testimonials.map((testimonial, index) => (
                            <Card key={index} className="bg-white shadow-md hover:shadow-lg transition-shadow duration-300 rounded-xl p-6">
                                <CardHeader>
                                    <CardTitle className="text-lg md:text-xl font-bold text-[#1B4965]">{testimonial.name}</CardTitle>
                                    <CardDescription className="text-sm md:text-base text-gray-600">{testimonial.school}</CardDescription>
                                </CardHeader>
                                <CardContent>
                                    <p className="text-gray-600 mb-4 italic">"{testimonial.quote}"</p>
                                    <div className="flex justify-center gap-1">
                                        {Array.from({ length: 5 }).map((_, i) => (
                                            <Star
                                                key={i}
                                                className={`h-5 w-5 ${i < testimonial.rating ? 'text-yellow-400' : 'text-gray-300'}`}
                                                fill={i < testimonial.rating ? 'currentColor' : 'none'}
                                            />
                                        ))}
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </div>
            </section>

            {/* CTA Section */}
            <section className="py-20 bg-[#1B4965]">
                <div className="container mx-auto px-4 md:px-6 text-center text-white">
                    <h2 className="text-3xl md:text-4xl font-bold mb-4">
                        {t('home.cta.title')}
                    </h2>
                    <Link to="/cennik">
                        <Button
                            size="lg"
                            className="text-lg bg-[#00D1A7] hover:bg-[#00B594] text-white mt-12"
                        >
                            {t('home.cta.buttonText')}
                        </Button>
                    </Link>
                </div>
            </section>

            {/* Short Faq */}
            <section className="py-20 bg-[#F8FAFC]">
                <div className="container mx-auto px-4 md:px-6">
                    <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-[#1B4965]">
                        {t('home.faq.title')}
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {faqs.map((faq, index) => (
                            <Card key={index} className="bg-white shadow-md hover:shadow-lg transition-shadow duration-300 ">
                                <CardHeader>
                                    <CardTitle className="text-xl text-[#1B4965] font-semibold">{faq.question}</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p className="text-gray-600">{faq.answer}</p>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}
