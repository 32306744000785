import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { logEvent } from "../analytics";
import { auth } from "../firebase";
import { Button } from "../components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Link2, Award, DollarSign, Star } from "lucide-react";

export default function PromotorsProgram() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [enrollSuccess, setEnrollSuccess] = useState("");
    const [redirectCountdown, setRedirectCountdown] = useState(null);

    // Licznik do przekierowania
    useEffect(() => {
        let timer;
        if (redirectCountdown !== null && redirectCountdown > 0) {
            timer = setInterval(() => {
                setRedirectCountdown((prev) => (prev > 1 ? prev - 1 : 0));
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [redirectCountdown]);

    // Sprawdzanie, czy użytkownik jest zalogowany
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
            setLoading(false);
        });
        return () => unsubscribe();
    }, []);

    // Jeśli trwa ładowanie stanu zalogowania
    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen bg-gradient-to-b from-gray-50 to-gray-100">
                <div className="text-2xl text-gray-700 animate-pulse">
                    {t("promotorsProgram.loading")}
                </div>
            </div>
        );
    }

    // Obsługa dołączania do programu (wysyłka żądania do backendu)
    const handleEnroll = async () => {
        setIsSubmitting(true);
        try {
            const token = await auth.currentUser.getIdToken();
            const response = await fetch(
                "https://us-central1-quizonator-a4513.cloudfunctions.net/api/admin/makeAmbassador",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ email: user.email }),
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || t("promotorsProgram.enrollError"));
            }

            const data = await response.json();

            // Ustawiamy komunikat o sukcesie z dynamicznym kodem
            setEnrollSuccess(
                t("promotorsProgram.enrollSuccess", {
                    ambassadorCode: data.ambassadorCode,
                })
            );

            setRedirectCountdown(5);

            // Logowanie zdarzenia w analityce
            logEvent("Enroll", "Click", "User joined the promotors program");
        } catch (error) {
            console.error("Błąd:", error);
            setEnrollSuccess(t("promotorsProgram.enrollError"));
        }
        setIsSubmitting(false);
    };

    // Pobranie list z tłumaczenia (zwracamy obiekty, dlatego { returnObjects: true })
    const timeline = t("promotorsProgram.timeline", { returnObjects: true });
    const benefits = t("promotorsProgram.benefits", { returnObjects: true });
    const faq = t("promotorsProgram.faq", { returnObjects: true });
    const testimonials = t("promotorsProgram.testimonials", { returnObjects: true });

    return (
        <>
            <Helmet>
                <title>{t("promotorsProgram.helmetTitle")}</title>
                <meta
                    name="description"
                    content={t("promotorsProgram.helmetDescription")}
                />
            </Helmet>

            <section className="py-20 md:py-32 bg-gradient-to-b from-gray-50 to-gray-100">
                <div className="container mx-auto px-6 md:px-10">
                    {/* Header */}
                    <header className="text-center mb-12 animate-fadeIn">
                        <h1 className="text-5xl md:text-6xl font-extrabold text-[#1B4965]">
                            {t("promotorsProgram.headerTitle")}
                        </h1>
                        <p className="text-xl md:text-2xl text-gray-700 mt-4 max-w-3xl mx-auto">
                            {t("promotorsProgram.headerDescription")}
                        </p>
                    </header>

                    {/* CTA */}
                    <div className="max-w-xl mx-auto bg-white rounded-2xl shadow-2xl p-10 mb-16 transform transition hover:scale-105">
                        {enrollSuccess ? (
                            <div className="bg-green-100 text-green-800 p-4 rounded-lg text-center text-xl">
                                {enrollSuccess}
                                {redirectCountdown !== null && (
                                    <div className="mt-4">
                                        <Button 
                                            onClick={() => navigate("/panel-promotora")}
                                            className="py-4 px-8 rounded-full font-bold bg-gradient-to-r from-[#FF7E5F] to-[#FD3A69] text-white shadow-lg hover:shadow-2xl transition-transform transform hover:scale-110 text-lg"
                                        >
                                            {redirectCountdown > 0
                                                ? `${t("promotorsProgram.redirectPanel")}`
                                                : t("promotorsProgram.redirectPanel")}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <>
                                <h2 className="text-3xl font-bold text-center text-[#1B4965] mb-6">
                                    {t("promotorsProgram.ctaTitle")}
                                </h2>
                                {user ? (
                                    <>
                                        <p className="text-center text-gray-700 mb-8 text-lg">
                                            {t("promotorsProgram.ctaDescription")}
                                        </p>
                                        <div className="flex justify-center">
                                            <Button
                                                onClick={handleEnroll}
                                                disabled={isSubmitting}
                                                className="py-4 px-8 rounded-full font-semibold bg-gradient-to-r from-[#00D1A7] to-[#00B594] text-white shadow-lg hover:shadow-xl transition-transform transform hover:scale-105"
                                            >
                                                {isSubmitting
                                                    ? t("promotorsProgram.enrollProcessing")
                                                    : t("promotorsProgram.enrollBtnText")}
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <p className="text-center text-gray-700 text-lg">
                                        {t("promotorsProgram.loginPrompt")}{" "}
                                        <Link to="/login" className="text-[#00D1A7] underline">
                                            {t("promotorsProgram.loginLinkText")}
                                        </Link>
                                    </p>
                                )}
                            </>
                        )}
                    </div>

                    {/* Timeline */}
                    <section className="mb-16">
                        <h2 className="text-4xl font-bold text-center text-[#1B4965] mb-8">
                            {t("promotorsProgram.timelineTitle")}
                        </h2>
                        <div className="relative container mx-auto px-4">
                            {/* Linia tła – widoczna tylko na desktopie */}
                            <div className="hidden md:block absolute top-8 left-0 w-full border-t-2 border-[#00D1A7]" />
                            {/* Kontener na kroki – flex na desktopie, stack na mobile */}
                            <div className="flex flex-col md:flex-row md:justify-between items-center gap-8 md:gap-4 max-w-5xl mx-auto">
                                {timeline.map((item, index) => (
                                    <div
                                        key={index}
                                        className="relative z-10 flex flex-col items-center text-center w-full md:w-auto"
                                    >
                                        {/* Kółko z numerem */}
                                        <div className="w-16 h-16 flex items-center justify-center bg-white border-4 border-[#00D1A7] rounded-full shadow-lg mb-4">
                                            <span className="text-2xl font-bold text-[#00D1A7]">
                                                {item.step}
                                            </span>
                                        </div>
                                        {/* Tekst pod kółkiem */}
                                        <div className="space-y-2">
                                            <div className="text-xl text-gray-700">{item.points}</div>
                                            <div className="text-2xl font-bold text-[#1B4965]">
                                                {item.bonus}
                                            </div>
                                            <div className="text-gray-600 text-sm max-w-xs mx-auto">
                                                {item.description}
                                            </div>
                                        </div>
                                        {/* Linia łącząca na mobile */}
                                        {index < timeline.length - 1 && (
                                            <div className="md:hidden w-1 h-12 bg-[#00D1A7] mx-auto mt-4" />
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>

                    {/* Benefits */}
                    <section className="mb-16">
                        <h2 className="text-4xl font-bold text-center text-[#1B4965] mb-8">
                            {t("promotorsProgram.benefitsTitle")}
                        </h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                            {benefits.map((benefit, index) => {
                                // Ikony LucideReact jako mapowanie string->komponent
                                let IconComponent = null;
                                switch (benefit.icon) {
                                    case "Link2":
                                        IconComponent = <Link2 className="h-12 w-12 text-[#00D1A7]" />;
                                        break;
                                    case "Award":
                                        IconComponent = <Award className="h-12 w-12 text-[#00D1A7]" />;
                                        break;
                                    case "DollarSign":
                                        IconComponent = <DollarSign className="h-12 w-12 text-[#00D1A7]" />;
                                        break;
                                    default:
                                        IconComponent = <Link2 className="h-12 w-12 text-[#00D1A7]" />;
                                        break;
                                }

                                return (
                                    <Card
                                        key={index}
                                        className="text-center bg-white shadow-lg hover:shadow-2xl transition-all duration-300 rounded-lg p-6"
                                    >
                                        <CardHeader>
                                            <div className="flex justify-center mb-4">{IconComponent}</div>
                                            <CardTitle className="text-2xl font-bold text-[#1B4965]">
                                                {benefit.title}
                                            </CardTitle>
                                        </CardHeader>
                                        <CardContent>
                                            <p className="text-gray-700 text-lg">{benefit.description}</p>
                                        </CardContent>
                                    </Card>
                                );
                            })}
                        </div>
                    </section>

                    {/* Testimonials */}
                    <section className="mb-16">
                        <h2 className="text-4xl font-bold text-center text-[#1B4965] mb-8">
                            {t("promotorsProgram.testimonialsTitle")}
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
                            {testimonials.map((testimonial, index) => (
                                <Card key={index} className="bg-white shadow-lg p-6 rounded-lg">
                                    <CardContent>
                                        <div className="flex mb-4">
                                            {[...Array(testimonial.stars)].map((_, i) => (
                                                <Star
                                                    key={i}
                                                    className="h-5 w-5 text-[#00D1A7] fill-current"
                                                />
                                            ))}
                                        </div>
                                        <p className="text-gray-700 mb-4">"{testimonial.text}"</p>
                                        <p className="text-sm font-semibold text-[#1B4965]">
                                            - {testimonial.name}
                                        </p>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </section>

                    {/* FAQ */}
                    <section className="mb-16">
                        <h2 className="text-4xl font-bold text-center text-[#1B4965] mb-8">
                            {t("promotorsProgram.faqTitle")}
                        </h2>
                        <div className="max-w-3xl mx-auto">
                            {faq.map((item, index) => (
                                <div key={index} className="mb-6 border-b pb-4">
                                    <h3 className="text-2xl font-semibold text-[#1B4965]">
                                        {item.question}
                                    </h3>
                                    <p className="text-gray-700 mt-2 text-lg">{item.answer}</p>
                                </div>
                            ))}
                        </div>
                    </section>

                    {/* Final CTA */}
                    <div className="text-center">
                        <h2 className="text-3xl font-bold text-[#1B4965] mb-6">
                            {t("promotorsProgram.finalCtaTitle")}
                        </h2>
                        <Button className="py-4 px-8 rounded-full font-semibold bg-gradient-to-r from-[#00D1A7] to-[#00B594] text-white shadow-lg hover:shadow-xl transition-transform transform hover:scale-105">
                            {t("promotorsProgram.finalCtaBtnText")}
                        </Button>
                    </div>
                </div>
            </section>
        </>
    );
}
