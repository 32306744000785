"use client"

import React, { useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import {
    CreditCard,
    Users,
    DollarSign,
    Copy,
    ChevronLeft,
    ArrowUpDown,
    Wallet,
    Star,
    Gift,
    Trophy,
} from "lucide-react"
import {
    FaTiktok,
    FaFacebookMessenger,
    FaFacebook,
    FaSquareXTwitter,
    FaInstagram,
} from "react-icons/fa6"
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
    CardDescription,
    CardFooter,
} from "../components/ui/card"
import { Button } from "../components/ui/button"
import { Progress } from "../components/ui/progress"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../components/ui/table"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../components/ui/dialog"

import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts"

import axios from "axios"
import { auth } from "../firebase"
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next"

// Komponent Top10Section (zmodyfikowany)
function Top10Section({ onBack }) {
    const { t } = useTranslation()

    return (
        <div className="space-y-8">
            <div className="flex items-center">
                <Button onClick={onBack} variant="ghost" size="icon" className="mr-2">
                    <ChevronLeft className="h-6 w-6 text-[#1B4965]" />
                </Button>
                <h2 className="text-2xl font-bold text-[#1B4965]">{t("ambassador.top10.title")}</h2>
            </div>
            <Card className="bg-gradient-to-r from-[#1B4965] to-[#00D1A7] text-white shadow-lg">
                <CardHeader>
                    <CardTitle className="text-3xl font-bold">{t("ambassador.top10.eliteTitle")}</CardTitle>
                    <p className="text-lg">{t("ambassador.top10.eliteSubtitle")}</p>
                </CardHeader>
            </Card>
            <Card className="shadow-lg">
                <CardHeader>
                    <CardTitle className="text-2xl font-bold text-[#1B4965]">
                        {t("ambassador.top10.whatYouGain")}
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    <p className="text-gray-700 mb-4" dangerouslySetInnerHTML={{ __html: t("ambassador.top10.description") }} />
                    <ul className="list-disc pl-6 text-gray-700 mb-4">
                        <li dangerouslySetInnerHTML={{ __html: t("ambassador.top10.commission.first") }} />
                        <li dangerouslySetInnerHTML={{ __html: t("ambassador.top10.commission.second") }} />
                        <li dangerouslySetInnerHTML={{ __html: t("ambassador.top10.commission.third") }} />
                        <li dangerouslySetInnerHTML={{ __html: t("ambassador.top10.commission.others") }} />
                    </ul>
                    <p className="text-gray-700 mb-4" dangerouslySetInnerHTML={{ __html: t("ambassador.top10.payout") }} />
                    <p className="text-[#00D1A7] font-semibold">{t("ambassador.top10.podium")}</p>
                </CardContent>
            </Card>
            <Card className="shadow-lg">
                <CardHeader>
                    <CardTitle className="text-2xl font-bold text-[#1B4965]">
                        {t("ambassador.top10.howToReachTop")}
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="space-y-6">
                        {/* Przykładowa karta dla TikToka */}
                        <div className="flex items-start">
                            <div className="w-12 h-12 flex items-center justify-center bg-[#00D1A7] text-white rounded-full">
                                <FaTiktok className="w-8 h-8" />
                            </div>
                            <div className="ml-4">
                                <p className="font-semibold text-[#1B4965]">
                                    {t("ambassador.top10.steps.tiktok.title")}
                                </p>
                                <p className="text-gray-700">
                                    {t("ambassador.top10.steps.tiktok.description")}
                                </p>
                            </div>
                        </div>
                        {/* Analogiczne sekcje dla Messenger, Instagram, Facebook, Twitter */}
                    </div>
                    <p className="text-sm text-[#00D1A7] font-semibold mt-4">
                        {t("ambassador.top10.finalMotivation")}
                    </p>
                </CardContent>
            </Card>
            <div className="text-center">
                <Button
                    className="bg-[#00D1A7] hover:bg-[#00B594] text-white font-semibold py-3 px-8 rounded-lg shadow-lg transition-transform transform hover:scale-105"
                    onClick={() => {
                        onBack()
                        window.scrollTo({ top: 0, behavior: "smooth" })
                    }}
                >
                    {t("ambassador.top10.backButton")}
                </Button>
            </div>
        </div>
    );
}

export default function AmbassadorPage() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [stats, setStats] = useState(null);
    const [userName, setUserName] = useState("");
    const [referralLink, setReferralLink] = useState("");
    const [totalEarnings, setTotalEarnings] = useState(0);
    const [availableForWithdrawal, setAvailableForWithdrawal] = useState(0);
    const [monthlyEarnings, setMonthlyEarnings] = useState(0);
    const [previousMonthEarnings, setPreviousMonthEarnings] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [points, setPoints] = useState(0);
    const [accountsCreated, setAccountsCreated] = useState(0);
    const [clicks, setClicks] = useState(0);
    const [pointsHistory, setPointsHistory] = useState([]);
    const [top10Ranking, setTop10Ranking] = useState([]);
    const [rankingLoading, setRankingLoading] = useState(true);
    const [activeSection, setActiveSection] = useState("dashboard");
    const [copied, setCopied] = useState(false);
    const [showTutorial, setShowTutorial] = useState(true);
    const [currentTutorialStep, setCurrentTutorialStep] = useState(0);
    const [position, setPosition] = useState(null); // Pozycja w rankingu
    const [rewardsEarned, setRewardsEarned] = useState({ freeMonth: false, freeSemester: false });
    const [rewardsActivated, setRewardsActivated] = useState({ freeMonth: false, freeSemester: false });
    const [showRewardMessage, setShowRewardMessage] = useState(false);
    const [rewardMessage, setRewardMessage] = useState("");
    const [rewardError, setRewardError] = useState("");
    const navigate = useNavigate();
    const { t } = useTranslation()

    const tutorialSteps = [
        { target: "#referral-link", content: "To Twój unikalny link – kopiuj i udostępniaj, aby zdobywać punkty!" },
        { target: "#points-progress", content: "Tu widzisz swoje punkty i jak blisko jesteś nagrody." },
        { target: "#points-history", content: "Sprawdź, jak zdobywałeś punkty – każdy zakup się liczy!" },
        { target: "#ranking", content: "Tu jest Top 10 – wbij na podium i zgarnij więcej!" },
        { target: "#rewards", content: "Tutaj widzisz swoje nagrody i możesz je aktywować ręcznie!" },
    ];
    const [earningsData, setEarningsData] = useState([
        { name: "Sty", earnings: 320 },
        { name: "Lut", earnings: 350 },
        { name: "Mar", earnings: 400 },
        { name: "Kwi", earnings: 380 },
        { name: "Maj", earnings: 450 },
        { name: "Cze", earnings: 420 },
    ]);
    const copyToClipboard = () => {
        navigator.clipboard.writeText(referralLink);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const activateReward = async (rewardType) => {
        try {
            const idToken = await auth.currentUser.getIdToken();
            const res = await axios.post(
                "https://us-central1-quizonator-a4513.cloudfunctions.net/api/ambassador/activateReward",
                { rewardType },
                { headers: { Authorization: `Bearer ${idToken}` } }
            );
            setRewardsActivated((prev) => ({
                ...prev,
                [rewardType]: true,
            }));
            setRewardMessage(res.data.message);
            setRewardError("");
            setShowRewardMessage(true);
        } catch (err) {
            console.error("Błąd aktywacji nagrody:", err);
            setRewardError(err.response?.data?.error || "Nie udało się aktywować nagrody. Spróbuj później.");
            setShowRewardMessage(true);
        }
    };

    useEffect(() => {
        let unsubscribe;
        async function fetchData() {
            try {
                unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
                    if (currentUser) {
                        const idToken = await currentUser.getIdToken();
                        try {
                            // Pobieranie statystyk ambasadora
                            const statsRes = await axios.get(
                                "https://us-central1-quizonator-a4513.cloudfunctions.net/api/ambassador/stats",
                                { headers: { Authorization: `Bearer ${idToken}` } }
                            );
                            const ambData = statsRes.data;

                            // Jeśli użytkownik nie jest ambasadorem – przekierowanie
                            if (!ambData.ambassadorType) {
                                navigate('/zarabiaj')
                                return;
                            }

                            setStats(ambData);
                            setUserName(ambData.name || currentUser.email);
                            setReferralLink(`https://quizonator.com/?ref=${ambData.ambassadorCode || ""}`);

                            if (ambData.ambassadorType === "premium") {
                                setTotalEarnings(ambData.totalRevenue || 0);
                                setAvailableForWithdrawal(ambData.totalCommission || 0);
                                setMonthlyEarnings(ambData.monthlyEarnings || 0);
                                setPreviousMonthEarnings(ambData.previousMonthEarnings || 0);
                                setTransactions(ambData.transactions || []);
                            } else {
                                setTransactions(ambData.transactions || []);
                                setAccountsCreated(ambData.totalReferred || 0);
                                setPoints(ambData.points || 0);
                                setClicks(ambData.totalClicks || 0);
                                setPointsHistory(ambData.accounts || []);

                                // Pobieranie pozycji i nagród
                                const rewardsRes = await axios.get(
                                    "https://us-central1-quizonator-a4513.cloudfunctions.net/api/ambassador/rewards",
                                    { headers: { Authorization: `Bearer ${idToken}` } }
                                );
                                setPosition(rewardsRes.data.position);
                                setRewardsEarned(rewardsRes.data.rewardsEarned);
                                setRewardsActivated(rewardsRes.data.rewardsActivated);
                            }

                            // Pobieranie rankingu Top 10
                            setRankingLoading(true);
                            const rankingRes = await axios.get(
                                "https://us-central1-quizonator-a4513.cloudfunctions.net/api/ambassador/ranking",
                                { headers: { Authorization: `Bearer ${idToken}` } }
                            );
                            setTop10Ranking(rankingRes.data.top10 || []);
                            setRankingLoading(false);

                            setLoading(false);
                        } catch (err) {
                            console.error("Błąd pobierania danych:", err);
                            // W przypadku błędu (np. 403) przekierowujemy od razu
                            navigate('/zarabiaj')
                        }
                    } else {
                        // Jeśli użytkownik nie jest zalogowany – przekieruj od razu
                        navigate('/zarabiaj')
                    }
                });
            } catch (err) {
                console.error("Błąd podczas inicjalizacji:", err);
                navigate('/zarabiaj')
            }
        }
        fetchData();
        return () => unsubscribe && unsubscribe();
    }, []);
    useEffect(() => {
        const hasSeenTutorial = localStorage.getItem("hasSeenTutorial");
        if (hasSeenTutorial) {
            setShowTutorial(false);
        }
    }, []);

    const [sortColumn, setSortColumn] = useState("date");
    const [sortDirection, setSortDirection] = useState("desc");


    const isPremium = stats && stats.ambassadorType === "premium";

    const handleSortChange = (column) => {

        if (sortColumn === column) {
            const newDirection = sortDirection === "asc" ? "desc" : "asc";
            setSortDirection(newDirection);
        } else {
            setSortColumn(column);
            setSortDirection("desc");
        }
    };
    const renderBasicDashboard = () => {
        const nextMilestone = points < 30 ? 30 : points < 150 ? 150 : "Top 10";
        const progressValue = points < 30
            ? (points / 30) * 100
            : points < 150
                ? (points / 150) * 100
                : 100;


        const sortedTransactions = [...transactions] // Create a copy to avoid mutating original
            .map((t) => ({
                ...t,
                // Format the date for display
                dateFormatted: t.date ? new Date(t.date).toLocaleDateString() : "Brak daty",
            }))
            .sort((a, b) => {
                // Sort by date
                if (sortColumn === "date") {
                    const dateA = a.date ? new Date(a.date) : new Date(0);
                    const dateB = b.date ? new Date(b.date) : new Date(0);
                    return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
                }
                // Sort by email/user
                else if (sortColumn === "email") {
                    const emailA = (a.email || "").toLowerCase();
                    const emailB = (b.email || "").toLowerCase();
                    return sortDirection === "asc"
                        ? emailA.localeCompare(emailB)
                        : emailB.localeCompare(emailA);
                }
                // Sort by type
                else if (sortColumn === "type") {
                    const typeA = (a.type || "").toLowerCase();
                    const typeB = (b.type || "").toLowerCase();
                    return sortDirection === "asc"
                        ? typeA.localeCompare(typeB)
                        : typeB.localeCompare(typeA);
                }
                // Sort by amount
                else if (sortColumn === "amount") {
                    const amountA = parseFloat(a.amount) || 0;
                    const amountB = parseFloat(b.amount) || 0;
                    return sortDirection === "asc" ? amountA - amountB : amountB - amountA;
                }
                // Sort by points
                else if (sortColumn === "points") {
                    const pointsA = parseInt(a.points) || 0;
                    const pointsB = parseInt(b.points) || 0;
                    return sortDirection === "asc" ? pointsA - pointsB : pointsB - pointsA;
                }
                return 0;
            });

        // Check if the sorting is actually happening
        return (
            <div className="flex flex-col md:flex-row gap-8">
                <div className="flex-1 space-y-8">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Card className="bg-gradient-to-r from-[#00D1A7] to-[#76cbf8] text-white shadow-lg">
                            <CardHeader>
                                <CardTitle className="text-3xl font-bold">
                                    {t("ambassador.dashboard.greeting", { userName })}
                                </CardTitle>
                                <p className="text-lg">
                                    {t("ambassador.dashboard.encouragement")}
                                </p>
                            </CardHeader>
                        </Card>
                    </motion.div>

                    {/* Nowa karta z informacją o zdobywaniu punktów */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <Card className="shadow-md hover:shadow-xl transition-shadow">
                            <CardHeader className="flex flex-row items-center justify-between pb-2">
                                <CardTitle className="text-sm font-medium text-gray-700">
                                    {t("ambassador.dashboard.cards.points.title")}
                                </CardTitle>
                                <Star className="h-5 w-5 text-[#00D1A7]" />
                            </CardHeader>
                            <CardContent>
                                <div className="text-3xl font-bold text-[#1B4965]">{points}</div>
                                <p className="text-sm text-gray-600">
                                    {t("ambassador.dashboard.cards.points.subtitle")}
                                </p>
                            </CardContent>
                        </Card>
                        <Card className="shadow-md hover:shadow-xl transition-shadow">
                            <CardHeader className="flex flex-row items-center justify-between pb-2">
                                <CardTitle className="text-sm font-medium text-gray-700">
                                    {t("ambassador.dashboard.cards.invited.title")}
                                </CardTitle>
                                <Users className="h-5 w-5 text-[#00D1A7]" />
                            </CardHeader>
                            <CardContent>
                                <div className="text-3xl font-bold text-[#1B4965]">{accountsCreated}</div>
                                <p className="text-sm text-gray-600">
                                    {t("ambassador.dashboard.cards.invited.subtitle")}
                                </p>
                            </CardContent>
                        </Card>
                        <Card className="shadow-md hover:shadow-xl transition-shadow">
                            <CardHeader className="flex flex-row items-center justify-between pb-2">
                                <CardTitle className="text-sm font-medium text-gray-700">
                                    {t("ambassador.dashboard.cards.clicks.title")}
                                </CardTitle>
                                <Gift className="h-5 w-5 text-[#00D1A7]" />
                            </CardHeader>
                            <CardContent>
                                <div className="text-3xl font-bold text-[#1B4965]">{clicks}</div>
                                <p className="text-sm text-gray-600">
                                    {t("ambassador.dashboard.cards.clicks.subtitle")}
                                </p>
                            </CardContent>
                        </Card>
                    </div>

                    <Card className="shadow-lg" id="points-progress">
                        <CardHeader>
                            <CardTitle className="text-2xl font-bold text-[#1B4965]">
                                {t("ambassador.dashboard.progress.title")}
                            </CardTitle>
                            <p className="text-gray-600">
                                {t("ambassador.dashboard.progress.nextGoal", {
                                    goal:
                                        nextMilestone === "Top 10"
                                            ? t("ambassador.top10.title")
                                            : `${nextMilestone} ${t("ambassador.dashboard.transactionHistory.headers.points")}`
                                })}
                            </p>
                        </CardHeader>
                        <CardContent>
                            <Progress value={progressValue} className="h-4 mb-4" />
                            <p className="text-sm text-gray-700">
                                {points < 30
                                    ? t("ambassador.dashboard.progress.remaining.monthly", { remaining: 30 - points })
                                    : points < 150
                                        ? t("ambassador.dashboard.progress.remaining.semester", { remaining: 150 - points })
                                        : t("ambassador.dashboard.progress.congrats")}
                            </p>
                            <p className="text-sm text-[#00D1A7] font-semibold mt-2">
                                {t("ambassador.dashboard.progress.motivation")}
                            </p>
                        </CardContent>
                    </Card>

                    <Card className="shadow-lg" id="referral-link">
                        <CardHeader>
                            <CardTitle className="text-2xl font-bold text-[#1B4965]">
                                {t("ambassador.dashboard.referral.title")}
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="flex items-center space-x-4">
                                <input
                                    type="text"
                                    value={referralLink}
                                    readOnly
                                    className="flex-grow p-3 border-2 border-[#00D1A7] rounded-lg text-[#1B4965] font-medium focus:outline-none focus:border-[#00B594]"
                                />
                                <Button
                                    onClick={copyToClipboard}
                                    className="bg-[#00D1A7] hover:bg-[#00B594] text-white font-semibold py-2 px-4 rounded-lg transition-transform transform hover:scale-105"
                                >
                                    <Copy className="h-4 w-4 mr-2" />
                                    {copied ? t("ambassador.dashboard.referral.copied") : t("ambassador.dashboard.referral.copy")}
                                </Button>
                            </div>
                            <p className="text-sm text-gray-600 mt-2">
                                {t("ambassador.dashboard.referral.hint")}
                            </p>
                        </CardContent>
                    </Card>

                    <Card className="shadow-lg bg-white rounded-2xl overflow-hidden border border-gray-100 hover:shadow-2xl transition-shadow duration-300 !p-0">
                        <div className="bg-gradient-to-r from-[#00D1A7] to-[#76cbf8] rounded-t-2xl p-4">
                            <CardTitle className="text-2xl font-bold text-white flex items-center gap-2">
                                <Star className="h-6 w-6 text-white" />
                                {t("ambassador.dashboard.pointsInfo.title")}
                            </CardTitle>
                        </div>
                        <CardContent className="p-6">
                            <ul className="space-y-4 text-gray-700">
                                <li className="flex items-center gap-3">
                                    <div className="w-8 h-8 flex items-center justify-center bg-[#00D1A7] text-white rounded-full text-sm font-semibold">
                                        10
                                    </div>
                                    <span>{t("ambassador.dashboard.pointsInfo.items.monthlySub")}</span>
                                </li>
                                <li className="flex items-center gap-3">
                                    <div className="w-8 h-8 flex items-center justify-center bg-[#00D1A7] text-white rounded-full text-sm font-semibold">
                                        50
                                    </div>
                                    <span>{t("ambassador.dashboard.pointsInfo.items.semesterPlan")}</span>
                                </li>
                                <li className="flex items-center gap-3">
                                    <div className="w-8 h-8 flex items-center justify-center bg-[#00D1A7] text-white rounded-full text-sm font-semibold">
                                        5
                                    </div>
                                    <span>{t("ambassador.dashboard.pointsInfo.items.smallPackage")}</span>
                                </li>
                                <li className="flex items-center gap-3">
                                    <div className="w-8 h-8 flex items-center justify-center bg-[#00D1A7] text-white rounded-full text-sm font-semibold">
                                        20
                                    </div>
                                    <span>{t("ambassador.dashboard.pointsInfo.items.mediumPackage")}</span>
                                </li>
                                <li className="flex items-center gap-3">
                                    <div className="w-8 h-8 flex items-center justify-center bg-[#00D1A7] text-white rounded-full text-sm font-semibold">
                                        40
                                    </div>
                                    <span>{t("ambassador.dashboard.pointsInfo.items.largePackage")}</span>
                                </li>
                            </ul>
                            <p className="text-sm text-gray-600 mt-4 italic">
                                {t("ambassador.dashboard.pointsInfo.footer")}
                            </p>
                        </CardContent>
                    </Card>

                    <Card className="shadow-lg" id="points-history">
                        <CardHeader>
                            <CardTitle className="text-2xl font-bold text-[#1B4965]">
                                {t("ambassador.dashboard.transactionHistory.title")}
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="overflow-x-auto">
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHead className="cursor-pointer hover:bg-gray-100">
                                                <div className="flex items-center" onClick={() => handleSortChange("date")}>
                                                    {t("ambassador.dashboard.transactionHistory.headers.date")}
                                                    {sortColumn === "date" && (
                                                        <span className="ml-1">
                                                            {sortDirection === "asc" ? "↑" : "↓"}
                                                        </span>
                                                    )}
                                                </div>
                                            </TableHead>
                                            <TableHead className="cursor-pointer hover:bg-gray-100">
                                                <div className="flex items-center" onClick={() => handleSortChange("email")}>
                                                    {t("ambassador.dashboard.transactionHistory.headers.user")}
                                                    {sortColumn === "email" && (
                                                        <span className="ml-1">
                                                            {sortDirection === "asc" ? "↑" : "↓"}
                                                        </span>
                                                    )}
                                                </div>
                                            </TableHead>
                                            <TableHead className="cursor-pointer hover:bg-gray-100">
                                                <div className="flex items-center" onClick={() => handleSortChange("type")}>
                                                    {t("ambassador.dashboard.transactionHistory.headers.type")}
                                                    {sortColumn === "type" && (
                                                        <span className="ml-1">
                                                            {sortDirection === "asc" ? "↑" : "↓"}
                                                        </span>
                                                    )}
                                                </div>
                                            </TableHead>
                                            <TableHead className="cursor-pointer text-right hover:bg-gray-100">
                                                <div className="flex items-center justify-end" onClick={() => handleSortChange("amount")}>
                                                    {t("ambassador.dashboard.transactionHistory.headers.amount")}
                                                    {sortColumn === "amount" && (
                                                        <span className="ml-1">
                                                            {sortDirection === "asc" ? "↑" : "↓"}
                                                        </span>
                                                    )}
                                                </div>
                                            </TableHead>
                                            <TableHead className="cursor-pointer text-right hover:bg-gray-100">
                                                <div className="flex items-center justify-end" onClick={() => handleSortChange("points")}>
                                                    {t("ambassador.dashboard.transactionHistory.headers.points")}
                                                    {sortColumn === "points" && (
                                                        <span className="ml-1">
                                                            {sortDirection === "asc" ? "↑" : "↓"}
                                                        </span>
                                                    )}
                                                </div>
                                            </TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {sortedTransactions.map((t, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{t.dateFormatted}</TableCell>
                                                <TableCell>{t.email}</TableCell>
                                                <TableCell>{t.type}</TableCell>
                                                <TableCell className="text-right">{t.amount}</TableCell>
                                                <TableCell className="text-right text-[#00D1A7] font-semibold">
                                                    {t.points}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </CardContent>
                    </Card>

                    <Card className="shadow-lg" id="rewards">
                        <CardHeader>
                            <CardTitle className="text-2xl font-bold text-[#1B4965]">
                                {t("ambassador.dashboard.rewards.title")}
                            </CardTitle>
                            <p className="text-gray-600">
                                {t("ambassador.dashboard.rewards.description")}
                            </p>
                        </CardHeader>
                        <CardContent className="py-6 space-y-6">
                            <div className="space-y-4">
                                <div className="flex flex-col md:flex-row items-center justify-between p-5 bg-gradient-to-r from-[#00D1A7]/20 to-[#76cbf8]/20 rounded-2xl shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1">
                                    <div className="mb-4 md:mb-0">
                                        <h3 className="text-gray-900 font-semibold text-xl">
                                            {t("ambassador.dashboard.rewards.freeMonth.title")}
                                        </h3>
                                        <p className="text-gray-600 text-sm">
                                            {t("ambassador.dashboard.rewards.freeMonth.description")}
                                        </p>
                                    </div>
                                    {rewardsEarned.freeMonth ? (
                                        rewardsActivated.freeMonth ? (
                                            <span className="text-green-600 font-bold bg-green-100 px-4 py-1 rounded-full shadow-md">
                                                {t("ambassador.dashboard.rewards.freeMonth.activated")}
                                            </span>
                                        ) : (
                                            <Button
                                                onClick={() => activateReward("freeMonth")}
                                                className="bg-[#00D1A7] hover:bg-[#00B594] text-white font-semibold py-2 px-8 rounded-full shadow-md transition-transform transform hover:scale-105"
                                                disabled={points < 30}
                                            >
                                                {t("ambassador.dashboard.rewards.freeMonth.activate")}
                                            </Button>
                                        )
                                    ) : (
                                        <span className="text-gray-500 font-medium text-lg">
                                            {t("ambassador.dashboard.rewards.freeMonth.notEarned")}
                                        </span>
                                    )}
                                </div>
                                <div className="flex flex-col md:flex-row items-center justify-between p-5 bg-gradient-to-r from-[#00D1A7]/20 to-[#76cbf8]/20 rounded-2xl shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1">
                                    <div className="mb-4 md:mb-0">
                                        <h3 className="text-gray-900 font-semibold text-xl">
                                            {t("ambassador.dashboard.rewards.freeSemester.title")}
                                        </h3>
                                        <p className="text-gray-600 text-sm">
                                            {t("ambassador.dashboard.rewards.freeSemester.description")}
                                        </p>
                                    </div>
                                    {rewardsEarned.freeSemester ? (
                                        rewardsActivated.freeSemester ? (
                                            <span className="text-green-600 font-bold bg-green-100 px-4 py-1 rounded-full shadow-md">
                                                {t("ambassador.dashboard.rewards.freeSemester.activated")}
                                            </span>
                                        ) : (
                                            <Button
                                                onClick={() => activateReward("freeSemester")}
                                                className="bg-[#00D1A7] hover:bg-[#00B594] text-white font-semibold py-2 px-8 rounded-full shadow-md transition-transform transform hover:scale-105"
                                                disabled={points < 150}
                                            >
                                                {t("ambassador.dashboard.rewards.freeSemester.activate")}
                                            </Button>
                                        )
                                    ) : (
                                        <span className="text-gray-500 font-medium text-lg">
                                            {t("ambassador.dashboard.rewards.freeSemester.notEarned")}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <p className="text-gray-600 font-medium text-sm mt-4">
                                {t("ambassador.dashboard.rewards.footer")}
                            </p>
                        </CardContent>
                    </Card>

                    <Card className="bg-gradient-to-r from-[#00D1A7] to-[#76cbf8] text-white shadow-lg" id="motivation">
                        <CardHeader>
                            <CardTitle className="text-2xl font-bold">
                                {t("ambassador.dashboard.motivation.title")}
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            <p className="text-lg text-white font-bold">
                                {t("ambassador.dashboard.motivation.subtitle")}
                            </p>
                            <Button
                                variant="outline"
                                className="mt-4 bg-white text-[#1B4965] hover:bg-gray-100 font-semibold border-none py-2 px-4 rounded-lg transition-transform transform hover:scale-105"
                                onClick={() => {
                                    setActiveSection("top10");
                                    window.scrollTo({ top: 0, behavior: "smooth" });
                                }}
                            >
                                {t("ambassador.dashboard.motivation.button")}
                            </Button>
                        </CardContent>
                    </Card>
                </div>

                <div className="w-full md:w-80">
                    <Card className="shadow-lg sticky top-20" id="ranking">
                        <CardHeader>
                            <CardTitle className="text-xl font-bold text-[#1B4965]">
                                {t("ambassador.top10.title")}
                            </CardTitle>
                            <p className="text-sm text-gray-600">
                                {t("ambassador.top10.eliteSubtitle")}
                            </p>
                        </CardHeader>
                        <CardContent>
                            {rankingLoading ? (
                                <div className="space-y-4">
                                    {[1, 2, 3, 4, 5].map((i) => (
                                        <div key={i} className="h-12 bg-gray-200 rounded-lg animate-pulse"></div>
                                    ))}
                                </div>
                            ) : (
                                <div className="space-y-4">
                                    {/* Miejsce 1 - Złoty */}
                                    <div className={`flex justify-between items-center p-3 rounded-lg shadow-sm ${position === 1 ? 'bg-yellow-100' : 'bg-gray-50'}`}>
                                        <div className="flex items-center gap-2">
                                            <Trophy className="h-6 w-6 text-yellow-500" />
                                            <span className={`font-semibold ${top10Ranking[0]?.ambassadorCode === stats?.ambassadorCode ? 'text-[#00D1A7]' : 'text-[#1B4965]'} text-lg truncate max-w-[150px]`}>
                                                {top10Ranking[0]?.name || t("ambassador.dashboard.transactionHistory.headers.user")}
                                            </span>
                                        </div>
                                        <span className="text-[#1B4965] font-bold text-lg whitespace-nowrap">
                                            {top10Ranking[0]?.points || 0} pkt
                                        </span>
                                    </div>

                                    {/* Miejsce 2 - Srebrny */}
                                    <div className={`flex justify-between items-center p-3 rounded-lg shadow-sm ${position === 2 ? 'bg-gray-200' : 'bg-gray-50'}`}>
                                        <div className="flex items-center gap-2">
                                            <Trophy className="h-6 w-6 text-gray-400" />
                                            <span className={`font-semibold ${top10Ranking[1]?.ambassadorCode === stats?.ambassadorCode ? 'text-[#00D1A7]' : 'text-[#1B4965]'} text-lg truncate max-w-[150px]`}>
                                                {top10Ranking[1]?.name || t("ambassador.dashboard.transactionHistory.headers.user")}
                                            </span>
                                        </div>
                                        <span className="text-[#1B4965] font-bold text-lg whitespace-nowrap">
                                            {top10Ranking[1]?.points || 0} pkt
                                        </span>
                                    </div>

                                    {/* Miejsce 3 - Brązowy */}
                                    <div className={`flex justify-between items-center p-3 rounded-lg shadow-sm ${position === 3 ? 'bg-orange-100' : 'bg-gray-50'}`}>
                                        <div className="flex items-center gap-2">
                                            <Trophy className="h-6 w-6 text-orange-600" />
                                            <span className={`font-semibold ${top10Ranking[2]?.ambassadorCode === stats?.ambassadorCode ? 'text-[#00D1A7]' : 'text-[#1B4965]'} text-lg truncate max-w-[150px]`}>
                                                {top10Ranking[2]?.name || t("ambassador.dashboard.transactionHistory.headers.user")}
                                            </span>
                                        </div>
                                        <span className="text-[#1B4965] font-bold text-lg whitespace-nowrap">
                                            {top10Ranking[2]?.points || 0} pkt
                                        </span>
                                    </div>

                                    {/* Pozostałe miejsca (4-10) */}
                                    <div className="space-y-2">
                                        {top10Ranking.slice(3).map((entry) => {
                                            const isCurrentUser = entry.ambassadorCode === stats?.ambassadorCode;
                                            return (
                                                <div
                                                    key={entry.rank}
                                                    className={`
                                flex justify-between items-center p-3 rounded-lg bg-gray-50 hover:bg-gray-100 transition-all duration-300
                                ${position === entry.rank ? 'border border-[#00D1A7] bg-[#00D1A7]/10 shadow-md' : ''}
                                ${isCurrentUser ? 'font-semibold text-[#00D1A7]' : 'text-gray-700'}
                              `}
                                                >
                                                    <div className="flex items-center gap-2">
                                                        {position === entry.rank && (
                                                            <span className="text-[#00D1A7] font-bold">★</span>
                                                        )}
                                                        <span className="text-base truncate max-w-[200px]">
                                                            {entry.rank}. {entry.name}
                                                        </span>
                                                    </div>
                                                    <span className="font-bold text-lg whitespace-nowrap">
                                                        {entry.points} pkt
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>

                                    {/* Informacja o miejscu użytkownika */}
                                    {position && position <= 10 && (
                                        <div className="mt-4 p-4 bg-[#00D1A7]/10 rounded-lg shadow-md border border-[#00D1A7]">
                                            <p className="text-lg text-[#1B4965] font-semibold">
                                                {t("ambassador.dashboard.transactionHistory.congratulations", { position })}
                                            </p>
                                        </div>
                                    )}
                                    {position && position > 10 && (
                                        <div className="mt-4 p-4 bg-[#00D1A7]/10 rounded-lg shadow-md border border-[#00D1A7]">
                                            <p className="text-lg text-[#1B4965] font-semibold">
                                                {t("ambassador.dashboard.transactionHistory.keepFighting", { position })}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            )}
                            <p className="text-sm text-[#00D1A7] font-semibold mt-4">
                                {t("ambassador.top10.finalMotivation")}
                            </p>
                        </CardContent>
                    </Card>
                    <AnimatePresence>
                        {showRewardMessage && (
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -20 }}
                                transition={{ duration: 0.3 }}
                                className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50"
                            >
                                <Card className="shadow-lg border border-green-200 rounded-lg p-4 bg-white">
                                    {rewardError ? (
                                        <p className="text-red-600 font-semibold">{rewardError}</p>
                                    ) : (
                                        <p className="text-green-600 font-semibold">{rewardMessage}</p>
                                    )}
                                    <Button
                                        variant="outline"
                                        className="mt-2"
                                        onClick={() => setShowRewardMessage(false)}
                                    >
                                        {t("ambassador.close")}
                                    </Button>
                                </Card>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </div>

        );
    };

    const renderPremiumDashboard = () => {
        return (
            <div className="space-y-8">
                <Card className="bg-gradient-to-r from-[#1B4965] to-[#00D1A7] text-white shadow-xl">
                    <CardHeader className="p-6">
                        <CardTitle className="text-4xl font-extrabold">
                            Witaj, {userName}!
                        </CardTitle>
                        <p className="text-xl">Twój premium panel ambasadora</p>
                    </CardHeader>
                </Card>
                <Card className="bg-gradient-to-r from-[#00D1A7] to-[#76cbf8] text-white shadow-xl">
                    <CardHeader className="p-6">
                        <CardTitle className="text-3xl font-bold">
                            Twój link do sukcesu
                        </CardTitle>
                    </CardHeader>
                    <CardContent className="px-6 pb-6">
                        <div className="flex flex-col sm:flex-row items-center gap-4">
                            <input
                                type="text"
                                value={referralLink}
                                readOnly
                                className="w-full sm:flex-1 p-4 border-2 border-white rounded-lg text-white font-medium bg-transparent focus:outline-none focus:border-gray-300"
                            />
                            <Button
                                onClick={copyToClipboard}
                                className="bg-white text-[#00D1A7] font-semibold py-3 px-6 rounded-lg transition-transform transform hover:scale-105"
                            >
                                <Copy className="h-5 w-5 mr-2" /> {copied ? "Skopiowano!" : "Kopiuj"}
                            </Button>
                        </div>
                        <p className="text-sm mt-4">
                            Udostępnij link i zarabiaj prowizje od sprzedaży!
                        </p>
                    </CardContent>
                </Card>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    <Card className="shadow-md hover:shadow-xl transition-shadow">
                        <CardHeader className="flex flex-row items-center justify-between pb-2 px-4 pt-4">
                            <CardTitle className="text-sm font-medium text-gray-700">Całkowity zarobek</CardTitle>
                            <DollarSign className="h-5 w-5 text-[#00D1A7]" />
                        </CardHeader>
                        <CardContent className="px-4 pb-4">
                            <div className="text-3xl font-bold text-[#1B4965]">{totalEarnings} zł</div>
                        </CardContent>
                    </Card>
                    <Card className="shadow-md hover:shadow-xl transition-shadow">
                        <CardHeader className="flex flex-row items-center justify-between pb-2 px-4 pt-4">
                            <CardTitle className="text-sm font-medium text-gray-700">Dostępne do wypłaty</CardTitle>
                            <Wallet className="h-5 w-5 text-[#00D1A7]" />
                        </CardHeader>
                        <CardContent className="px-4 pb-4">
                            <div className="text-3xl font-bold text-[#1B4965]">{availableForWithdrawal} zł</div>
                        </CardContent>
                    </Card>
                    <Card className="shadow-md hover:shadow-xl transition-shadow">
                        <CardHeader className="flex flex-row items-center justify-between pb-2 px-4 pt-4">
                            <CardTitle className="text-sm font-medium text-gray-700">Zaproszeni</CardTitle>
                            <Users className="h-5 w-5 text-[#00D1A7]" />
                        </CardHeader>
                        <CardContent className="px-4 pb-4">
                            <div className="text-3xl font-bold text-[#1B4965]">{stats.totalReferred || 0}</div>
                        </CardContent>
                    </Card>
                    <Card className="shadow-md hover:shadow-xl transition-shadow">
                        <CardHeader className="flex flex-row items-center justify-between pb-2 px-4 pt-4">
                            <CardTitle className="text-sm font-medium text-gray-700">Sprzedaże</CardTitle>
                            <CreditCard className="h-5 w-5 text-[#00D1A7]" />
                        </CardHeader>
                        <CardContent className="px-4 pb-4">
                            <div className="text-3xl font-bold text-[#1B4965]">{stats.totalPaidAccounts || 0}</div>
                        </CardContent>
                    </Card>
                </div>
                <Card className="shadow-xl">
                    <CardHeader className="p-6">
                        <CardTitle className="text-2xl font-bold text-[#1B4965]">Stawka prowizji</CardTitle>
                    </CardHeader>
                    <CardContent className="px-6 pb-6">
                        <p className="text-4xl font-extrabold text-[#00D1A7]">30%</p>
                        <p className="text-sm text-gray-600">od każdej sprzedaży przez Twój link referencyjny</p>
                    </CardContent>
                </Card>
                <Card className="shadow-xl">
                    <CardHeader className="p-6">
                        <CardTitle className="text-2xl font-bold text-[#1B4965]">Zarobki w czasie</CardTitle>
                    </CardHeader>
                    <CardContent className="px-6 pb-6">
                        <div className="h-80">
                            <ResponsiveContainer width="100%" height="100%">
                                <AreaChart data={earningsData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Area
                                        type="monotone"
                                        dataKey="earnings"
                                        stroke="#00D1A7"
                                        fill="#00D1A7"
                                        fillOpacity={0.3}
                                    />
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                    </CardContent>
                </Card>
                <Card className="shadow-xl">
                    <CardHeader className="p-6">
                        <CardTitle className="text-2xl font-bold text-[#1B4965]">Ostatnie transakcje</CardTitle>
                    </CardHeader>
                    <CardContent className="px-6 pb-6">
                        {transactions.length > 0 ? (
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead>Data</TableHead>
                                        <TableHead>Typ planu</TableHead>
                                        <TableHead className="text-right">Kwota</TableHead>
                                        <TableHead className="text-right">Prowizja</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {transactions.slice(0, 5).map((transaction) => (
                                        <TableRow key={transaction.id}>
                                            <TableCell>{transaction.date}</TableCell>
                                            <TableCell>{transaction.type}</TableCell>
                                            <TableCell className="text-right">{transaction.amount} zł</TableCell>
                                            <TableCell className="text-right text-[#00D1A7] font-semibold">
                                                {transaction.commission} zł
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        ) : (
                            <p className="text-gray-600">Brak ostatnich transakcji.</p>
                        )}
                        <Button
                            onClick={() => setActiveSection("transactions")}
                            variant="outline"
                            className="mt-4"
                        >
                            Zobacz wszystkie transakcje
                        </Button>
                    </CardContent>
                </Card>
                <div className="text-center">
                    <Dialog>
                        <DialogTrigger asChild>
                            <Button
                                className="bg-[#00D1A7] hover:bg-[#00B594] text-white font-semibold py-3 px-8 rounded-lg shadow-xl transition-transform transform hover:scale-105"
                            >
                                Wypłać środki
                            </Button>
                        </DialogTrigger>
                        <DialogContent>
                            <DialogHeader>
                                <DialogTitle>Wypłata środków</DialogTitle>
                                <DialogDescription>
                                    Aktualnie masz do wypłaty {availableForWithdrawal} zł. Podaj kwotę, którą chcesz wypłacić.
                                </DialogDescription>
                            </DialogHeader>
                            <div className="grid gap-4 py-4">
                                <div className="grid grid-cols-4 items-center gap-4">
                                    <label htmlFor="amount" className="text-right">Kwota</label>
                                    <input
                                        id="amount"
                                        defaultValue={availableForWithdrawal}
                                        className="col-span-3 p-2 border rounded"
                                    />
                                </div>
                            </div>
                            <Button type="submit" className="bg-[#00D1A7] hover:bg-[#00B594] text-white">
                                Zatwierdź wypłatę
                            </Button>
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
        );
    };

    const renderTransactions = () => (
        <div className="space-y-6">
            <div className="flex items-center">
                <Button onClick={() => setActiveSection("dashboard")} variant="ghost" size="icon" className="mr-2">
                    <ChevronLeft className="h-4 w-4" />
                </Button>
                <h2 className="text-2xl font-bold text-blue-700">Historia transakcji</h2>
            </div>
            <Card>
                <CardContent>
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead className="cursor-pointer" onClick={() => handleSortChange("date")}>
                                    Data {sortColumn === "date" && <ArrowUpDown className="inline ml-1" />}
                                </TableHead>
                                <TableHead className="cursor-pointer" onClick={() => handleSortChange("type")}>
                                    Typ planu {sortColumn === "type" && <ArrowUpDown className="inline ml-1" />}
                                </TableHead>
                                <TableHead className="text-right cursor-pointer" onClick={() => handleSortChange("amount")}>
                                    Kwota {sortColumn === "amount" && <ArrowUpDown className="inline ml-1" />}
                                </TableHead>
                                <TableHead className="text-right cursor-pointer" onClick={() => handleSortChange("commission")}>
                                    Twoja prowizja {sortColumn === "commission" && <ArrowUpDown className="inline ml-1" />}
                                </TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {transactions.map((transaction) => (
                                <TableRow key={transaction.id}>
                                    <TableCell>{transaction.date}</TableCell>
                                    <TableCell>{transaction.type}</TableCell>
                                    <TableCell className="text-right">{transaction.amount} zł</TableCell>
                                    <TableCell className="text-right font-medium text-green-600">{transaction.commission} zł</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </div>
    );

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-50">
                <p className="text-gray-500 animate-pulse">   {t("ambassador.loading")}</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-50 text-red-500">
                {error}
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-50 p-6">
            <div className="max-w-6xl mx-auto">
                <motion.div
                    key={activeSection}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.2 }}
                >
                    {isPremium
                        ? activeSection === "dashboard"
                            ? renderPremiumDashboard()
                            : renderTransactions()
                        : activeSection === "dashboard"
                            ? renderBasicDashboard()
                            : <Top10Section onBack={() => setActiveSection("dashboard")} />}
                </motion.div>
            </div>

        </div>
    );
}
